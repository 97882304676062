import { useDebounceFn } from '@umijs/hooks';
import { Select, Spin } from 'antd';
import React, { useState } from 'react';
import { request, useRequest } from 'umi';
const Option = Select.Option;

export type Props = {
  value?: string;
  className?: string;
  onChange?: (
    value: string,
  ) => any | ((value: string, option?: { current: string[] }) => Promise<void>);
  allowClear?: boolean;
  disabled?: boolean;
  debounceInterval?: number;
};

export const UserSelectWithLink = (props: Props) => {
  const { value, onChange, allowClear, disabled, debounceInterval = 300 } = props;

  const { run, data, loading } = useRequest(
    (params) =>
      request('/users/general', {
        params: params,
      }),
    {
      defaultParams: {
        searchParam: props.value,
      } as any,
      refreshDeps: [props.value],
    },
  );

  const { run: fetchUser } = useDebounceFn((input: string) => {
    run({
      searchParam: input.trim(),
    });
  }, debounceInterval);

  const [selectedUser, setSelectedUser] = useState(value);

  const handleChange = (userId: string) => {
    setSelectedUser(null);
    onChange && onChange(userId);
  };

  return (
    <Select
      showSearch
      value={selectedUser}
      placeholder="Select User"
      notFoundContent={loading ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={fetchUser}
      onChange={handleChange}
      style={{ width: '100%' }}
      allowClear={allowClear}
      disabled={disabled}
    >
      {data?.map(
        (d: {
          id: string;
          name: string;
          email: string;
          accountNumber: string;
          vendor: { name: string };
        }) => {
          return (
            <Option value={d.id} key={d.id}>
              {d.name || '-'} - {d.email} - {d.accountNumber} ({d.vendor.name})
            </Option>
          );
        },
      )}
    </Select>
  );
};
