import { RequestData } from '@ant-design/pro-table';
import request from 'umi-request';

export interface CurrencyListItem {
  type: string;
  name: string;
  sequence: number;
  memoSupported: boolean;
  displayDecimal: number;
}

export async function queryCurrencies(params: any): Promise<RequestData<CurrencyListItem>> {
  const data = await request<RequestData<CurrencyListItem>>('/currencies', {
    params
  });
  return data;
}
