import { RequestData } from '@ant-design/pro-table';
import moment, { Moment } from 'moment-timezone';
import { request } from 'umi';

export interface TransactionsListItem {
  walletId?: string;
  walletLabel?: string;
  walletName?: string;
  currency?: string;
  intent?: string;
  date: string;
  refId: string;
  bookEntryType?: string;
  feeAmount?: string;
  status?: string;
  credit?: string;
  debit?: string;
  balance?: number;
  intentId?: string;
  transferId?: string;
  bookEntryAccount?: string;
  instructionIntent?: string;
}

export interface BankAccountListItem {
  id?: string;
  userId?: string;
  status?: string;
  accountHolderName?: string;
  accountNumber?: string;
  accountType?: string;
  bankDetail?: string;
  bankName?: string;
  country?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface WalletBalanceItem {
  availableBalanceUsdEquivalent: string;
  totalBalanceUsdEquivalent: string;
  availableBalanceHkdEquivalent: string;
  totalBalanceHkdEquivalent: string;
  currencies: Currency[];
  wallets: Wallet[];
}
export interface Currency {
  totalBalance: string;
  availableBalance: string;
  totalBalanceUsdEquivalent: string;
  availableBalanceUsdEquivalent: string;
  asset: string;
}

export interface Balance {
  availableBalance: string;
  totalBalance: string;
  availableBalanceUsdEquivalent: string;
  totalBalanceUsdEquivalent: string;
  asset: string;
  assetType: string;
  address: string;
  ledgerBalance: string;
}

export interface Wallet {
  balances: Balance[];
  id: string;
  label: string;
  name: string;
  accountNumber: string;
  totalBalanceUsdEquivalent: string;
  availableBalanceUsdEquivalent: string;
  totalBalanceHkdEquivalent: string;
  availableBalanceHkdEquivalent: string;
  overdraft?: boolean;
}

export interface WalletSolution {
  balances?: Balance[];
  id?: string;
  label?: string;
  name?: string;
  accountNumber?: string;
  totalBalanceUsdEquivalent?: string;
  availableBalanceUsdEquivalent?: string;
  totalBalanceHkdEquivalent?: string;
  availableBalanceHkdEquivalent?: string;
  overdraft?: boolean;
  asset?: string;
}

export interface AllInstructionListItem {
  time: Date;
  accountNumber: string;
  email: string;
  intent: InstructionIntent;
  qty1: string;
  ccy1: string;
  qty2: string;
  ccy2: string;
  rate: string;
  status: string;
  refId: string;
  intentId: string;
  userId: string;
  userName: string;
}

export enum InstructionStatus {
  NEW_AND_PENDING = 'NEW_AND_PENDING',
  CANCEL = 'CANCEL',
  CAPTURED = 'CAPTURED',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  LOCKED = 'LOCKED',
  SCHEDULED = 'SCHEDULED',
  SUCCESS = 'SUCCESS',
  USER_CANCEL = 'USER_CANCEL',
  VOID = 'VOID'
}

export enum InstructionFeature {
  DIGITAL_DEPOSIT = 'DIGITAL_DEPOSIT',
  WITHDRAW_FIAT = 'WITHDRAW_FIAT',
  WITHDRAW_DIGITAL = 'WITHDRAW_DIGITAL',
  DEPOSIT_FIAT = 'DEPOSIT_FIAT',
  COMMODITIES = 'COMMODITIES',
  COMMODITIES_DEPOSIT = 'COMMODITIES_DEPOSIT',
  COMMODITIES_WITHDRAW = 'COMMODITIES_WITHDRAW',
  TRADER_BURN = 'TRADER_BURN',
  TRADER_MINT = 'TRADER_MINT',
  REPAY = 'REPAY',
  EXCHANGE = 'EXCHANGE',
  BILL_PAYMENT = 'BILL_PAYMENT',
  TRANSFER_P2P = 'TRANSFER_P2P',
  TRANSFER_SELF = 'TRANSFER_SELF',
  GENERAL_INSTRUCTION = 'GENERAL_INSTRUCTION',
  FEE_PAYOUT = 'FEE_PAYOUT'
}

export enum InstructionIntent {
  ADJUSTMENT = 'ADJUSTMENT',
  ASSET_INJECTION = 'ASSET_INJECTION',
  ASSET_WITHDRAWAL = 'ASSET_WITHDRAWAL',
  BILL_PAYMENT = 'BILL_PAYMENT',
  CARD_PAYMENT = 'CARD_PAYMENT',
  CHARGE = 'CHARGE',
  COMMISSION = 'COMMISSION',
  COMMODITIES = 'COMMODITIES',
  COMMODITIES_DEPOSIT = 'COMMODITIES_DEPOSIT',
  COMMODITIES_WITHDRAW = 'COMMODITIES_WITHDRAW',
  DEPOSIT_DIGITAL = 'DEPOSIT_DIGITAL',
  DEPOSIT_FIAT = 'DEPOSIT_FIAT',
  EXCHANGE = 'EXCHANGE',
  FOREX = 'FOREX',
  INSURANCE = 'INSURANCE',
  REPAY = 'REPAY',
  SECURITIES_APPLICATION = 'SECURITIES_APPLICATION',
  SECURITIES_TRANSFER = 'SECURITIES_TRANSFER',
  TRADER_BURN = 'TRADER_BURN',
  TRADER_MINT = 'TRADER_MINT',
  TRANSFER = 'TRANSFER',
  TRANSFER_P2P = 'TRANSFER_P2P',
  TRANSFER_SELF = 'TRANSFER_SELF',
  WITHDRAW_DIGITAL = 'WITHDRAW_DIGITAL',
  WITHDRAW_FIAT = 'WITHDRAW_FIAT',
  FEE_PAYOUT = 'FEE_PAYOUT',
  FUND_SUBSCRIPTION = 'FUND_SUBSCRIPTION',
  BOND_SUBSCRIPTION = 'BOND_SUBSCRIPTION',
  CHANGE_BENEFICIARIES = 'CHANGE_BENEFICIARIES',
  INSURANCE_ENQUIRY = 'INSURANCE_ENQUIRY',
  SETTLEMENT = 'SETTLEMENT',
  EXCHANGE_SUB_ACCOUNT_APPLICATION = 'EXCHANGE_SUB_ACCOUNT_APPLICATION',
  EXCHANGE_SUB_ACCOUNT_TRANSFER = 'EXCHANGE_SUB_ACCOUNT_TRANSFER',
  EXCHANGE_SUB_ACCOUNT_MONTHLY_FEE = 'EXCHANGE_SUB_ACCOUNT_MONTHLY_FEE',
  FUND_DISTRIBUTION = 'FUND_DISTRIBUTION'
}

export enum InstructionEntryType {
  'CREDIT' = 'CREDIT',
  'DEBIT' = 'DEBIT',
  'NONE' = 'NONE'
}

export enum InstructionStatusGroup {
  NEW = 'New',
  REQUESTED = 'Requested',
  PROCESSING = 'Processing',
  PENDING = 'Pending',
  PENDING_FUNDING = 'Pending Funding',
  PENDING_SETTLEMENT = 'Pending Settlement',
  PENDING_PAYMENT = 'Pending Payment',
  PENDING_APPROVAL = 'Pending Approval',
  PENDING_WITH_FAULT = 'Pending With Fault',
  PENDING_AUTHORIZATION = 'Pending Authorization',
  PENDING_BLOCK_CONFIRMATION = 'Pending Block Confirmation'
}

export const noneTradableWalletLabels: string[] = ['insurance', 'securities', 'alternative'];

export async function getTransactions(params: any): Promise<RequestData<TransactionsListItem>> {
  if (params.date) {
    params.fromDate = params.date[0];
    params.toDate = params.date[1];
    delete params.date;
  }
  const data = await request<RequestData<TransactionsListItem>>('/wallet/transactions', {
    params
  });
  return data;
}

export async function getAllInstruction(params: {
  userId?: string;
  intent?: string;
  fromDate?: string;
  toDate?: string;
  vendorId?: number;
  refId?: string;
  status?: string;
  date?: Moment[];
  searchStatus?: string;
}): Promise<RequestData<AllInstructionListItem>> {
  if (params.date && params.date.length > 0) {
    params.fromDate = moment(params.date[0]).format('YYYY-MM-DD');
    params.toDate = moment(params.date[1]).format('YYYY-MM-DD');
    delete params.date;
  }
  const data = await request<RequestData<AllInstructionListItem>>('/wallet/instruction/all', {
    params
  });
  return data;
}

export async function getBankAccount(params: any): Promise<RequestData<BankAccountListItem>> {
  // if (params.date) {
  //   params.fromDate = params.date[0];
  //   params.toDate = params.date[1];
  //   delete params.date;
  // }
  const data = await request<RequestData<BankAccountListItem>>('/user-bank-accounts', {
    params
  });
  return data;
}

export async function getBalances(params: { userId: string }) {
  const data = await request<WalletBalanceItem>(`/wallet/balances`, { params });
  return {
    data
  };
}

export const getEarnMaturityDateText = (date: Date | string | null) => {
  if (date === null) {
    return 'Flexible';
  }

  const today = moment();
  const maturityDate = moment(date);
  const daysLeft = today.diff(maturityDate, 'days');
  const daysDiff = Math.abs(daysLeft);

  if (daysDiff === 0) {
    return 'Today';
  }

  // Check if the maturity date is expired
  if (today.isAfter(maturityDate)) {
    if (daysDiff === 1) {
      return `${daysDiff} Day Before`;
    }
    return `${daysDiff} Days Before`;
  }

  if (daysDiff === 1) {
    return `In ${daysDiff} day`;
  }

  return `In ${daysDiff} Days`;
};
