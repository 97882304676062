import { useCallback, useState } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';

export const useStreamEvent = (event: string, handler: (data: any, message: string) => void) => {
  const socketUrl = window['env']['ADMIN_API_URL'].replace('http', 'ws');

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    shouldReconnect: (closeEvent) => true,
    queryParams: {
      authToken: localStorage.getItem('token') as string
    },
    onMessage: (eventStr: MessageEvent) => {
      const messageEvent = JSON.parse(eventStr.data);
      if (messageEvent.event === event) {
        handler(messageEvent.data, messageEvent.event);
      }
      return;
    },
    share: true,
  });

  const send = useCallback(() => sendMessage('Hello'), []);

  return {
    send: send
  }
}
