import { useDebounceFn } from '@umijs/hooks';
import { Col, Input, notification, Row, Select, Spin } from 'antd';
import React, { useState } from 'react';
import { request, useRequest } from 'umi';

export type Props = {
  onChange?: (value: string) => void | ((value: string, option?: { current: string[] }) => Promise<void>);
  debounceInterval?: number;
};

export const CardNumberSearch = (props: Props) => {
  const { onChange, debounceInterval = 700 } = props;

  const [searchText, setSearchText] = useState<string>();

  const handleCardListNavigation = (cardList: any[]) => {
    if (cardList.length === 1) {
      // just have 1 card => go to card detail page
      return `/cms/cards/${cardList[0].id}/${cardList[0].userId}`;
    }

    const creditLine = [...new Set(cardList.map((cl) => cl.creditLineId))];
    if (creditLine.length === 1) {
      // just have 1 credit line many cards => go to card account detail page
      return `/users/${cardList[0].userId}?tab=card&creditId=${cardList[0].creditLineId}&cparams={"tab":"card","current":1,"pageSize":20,"creditLineId":"${cardList[0].creditLineId}"}`;
    }

    const last4 = [...new Set(cardList.map((cl) => cl.last4))];
    if (last4.length === 1) {
      const filteredCards = cardList.filter((cl) => cl.last4 === last4[0]);
      const accountIds = [...new Set(filteredCards.map((cl) => cl.creditLineId))];

      if (accountIds.length === 1) {
        // exact match but client with multiple accounts => go to card account details page
        return `/users/${cardList[0].userId}?tab=card&creditId=${cardList[0].creditLineId}&cparams={"tab":"card","current":1,"pageSize":20,"creditLineId":"${cardList[0].creditLineId}"}`;
      }
      // just have 1 last4 => go to card list page with filtered result

      return `/cms/cards?last4=${last4[0]}`;
    }

    const accountLabel = [...new Set(cardList.map((cl) => cl.label))];
    if (accountLabel.length === 1) {
      return `/cms/card-account?label=${accountLabel[0]}`;
    }
    return '';
  };

  const { loading, run } = useRequest(
    (params) =>
      request('/cms/cards/search/card', {
        params: params,
        getResponse: true
      }),
    {
      defaultParams: {
        keyword: undefined
      } as any,
      manual: true,
      onSuccess: (data) => {
        setSearchText(undefined);
        if (data.message) {
          notification.error({
            message: data.message
          });
        } else {
          const cardList = data.data;
          if (cardList.length > 0) {
            onChange?.(handleCardListNavigation(cardList));
          } else {
            notification.error({
              message: 'No card found'
            });
          }
        }
      },
      onError: (error) => {
        notification.error({
          message: 'Error fetching card data',
          description: error.message
        });
      }
    }
  );

  const { run: searchCard } = useDebounceFn((input: string) => {
    run({
      keyword: input.trim()
    });
  }, debounceInterval);

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== undefined && e.target.value.trim() !== '') {
      let input = e.target.value;
      setSearchText(input);
      setTimeout(() => {
        searchCard(input);
      }, debounceInterval);
    }
  };

  return (
    <Row>
      <Col span={20}>
        <Input value={searchText} disabled={loading} onInput={onInput} placeholder="Search Card" />
      </Col>
      <Col span={4}>{loading && <Spin style={{ marginLeft: 8 }} />}</Col>
    </Row>
  );
};
