import { request } from '@@/plugin-request/request';
import { RequestData } from '@ant-design/pro-table';

export interface CardApplicationItem {
  userId: string;
  accountNumber: string;
  countryCode: string;
  phone: string;
  email: string;
  idNumber: string;
  kycLevel: {
    id: number;
    name: string;
  };
  metadata: object;
  name: string;
  relationshipManager: object;
  vendor: {
    id: number;
    name: string;
  };
  application: Application[];
  solutionName: string;
}
export interface Application {
  id: string;
  cardEmbossedName: string;
  cardPhone: string;
  cardProduct: string;
  applicationTime: Date;
  delivery_method: string;
  status: string;
  cardLimit: number;
  operator?: string;
  address?: string;
  cardApplicationRemark?: string;
  creditLineId?: string;
  cardAccountType?: string;
}

export enum CardApplicationStatus {
  NEW = 'NEW',
  ISSUED = 'ISSUED',
  CANCELLED = 'CANCELLED',
}
export async function getCardApplicationList(
  params: any,
): Promise<RequestData<CardApplicationItem>> {
  let data = await request<RequestData<CardApplicationItem>>('/cms/cards/application/list', {
    params,
  });
  return data;
}
