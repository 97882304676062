// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'C:/Code/Unisoft/utx/projects/utx-ops-web/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/dashboard",
        "name": "Dashboard",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__welcome' */'@/pages/welcome'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Pending Instructions",
        "path": "/pending-instructions",
        "routes": [
          {
            "path": "/pending-instructions/payment",
            "name": "Instruction",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__payment-instructions__index' */'@/pages/payment-instructions/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/pending-instructions/dd-kyc",
            "name": "KYC Doc List",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__kyc-documents__index' */'@/pages/kyc-documents/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/pending-instructions/account-activation",
            "name": "Account Activation",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account-activation-instructions__index' */'@/pages/account-activation-instructions/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/pending-instructions/beneficiaries",
            "name": "Beneficiaries",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__beneficiaries__index' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/beneficiaries/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/pending-instructions/cra-form",
            "name": "CRA Form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cra-form__index' */'@/pages/cra-form/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/pending-instructions/digital-deposits",
            "name": "Digital Deposits",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digital-deposit__digitalDepositPending' */'@/pages/digital-deposit/digitalDepositPending'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/users",
        "name": "Users & Accounts",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__users__index' */'@/pages/users/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/solution-application",
        "name": "Solution Applcation",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__solution-application__index' */'@/pages/solution-application/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Client Accounts",
        "path": "/client-account",
        "routes": [
          {
            "path": "/client-account/wallet-accounts",
            "name": "Wallet Accounts",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallet-account__index' */'@/pages/wallet-account/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/client-account/client-deposit-accounts",
            "name": "Client Deposit Accounts",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__client-deposit-accounts__index' */'@/pages/client-deposit-accounts/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/client-account/od-account",
            "name": "OD Account",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__od-account__index' */'@/pages/od-account/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "icon": "VerticalAlignTopOutlined",
        "path": "/client-documents",
        "name": "Client Documents",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__client-document__index' */'@/pages/client-document/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/name-check",
        "exact": true,
        "name": "Name Check",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__name-check__index' */'@/pages/name-check/index'), loading: LoadingComponent})
      },
      {
        "path": "/name-check/:id",
        "exact": true,
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__name-check-detail__index' */'@/pages/name-check-detail/index'), loading: LoadingComponent})
      },
      {
        "name": "Instructions",
        "path": "/instructions",
        "collapse": false,
        "routes": [
          {
            "path": "/instructions/all",
            "exact": true,
            "name": "All",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__all-instruction-list__index' */'@/pages/all-instruction-list/index'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/scheduled",
            "name": "Scheduled Instruction",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__scheduled-instruction-list__index' */'@/pages/scheduled-instruction-list/index'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/general-instruction",
            "exact": true,
            "name": "General Instruction",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__general-instruction__index' */'@/pages/general-instruction/index'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/general-instruction/:id",
            "exact": true,
            "name": "General Instruction Details",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__general-instruction__detail' */'@/pages/general-instruction/detail'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/fiat-withdraws",
            "exact": true,
            "name": "Fiat Withdraw",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fiat-withdraw__index' */'@/pages/fiat-withdraw/index'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/fiat-withdraws/:id",
            "exact": true,
            "name": "Fiat Withdraw Details",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fiat-withdraw__detail' */'@/pages/fiat-withdraw/detail'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/fiat-deposits",
            "exact": true,
            "name": "Fiat Deposits",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fiat-deposit__index' */'@/pages/fiat-deposit/index'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/fiat-deposits/new",
            "exact": true,
            "name": "New Fiat Deposit",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fiat-deposit__new' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/fiat-deposit/new'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/fiat-deposits/:id",
            "exact": true,
            "name": "Fiat Deposits Details",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fiat-deposit__detail' */'@/pages/fiat-deposit/detail'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/cash-in-out",
            "name": "Cash In/Out",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cash-in-out__index' */'@/pages/cash-in-out/index'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/digital-withdraws",
            "name": "Digital Withdraw",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digital-withdraw__index' */'@/pages/digital-withdraw/index'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/digital-withdraws/:id",
            "hideInMenu": true,
            "name": "Digital Withdraw Details",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digital-withdraw__detail' */'@/pages/digital-withdraw/detail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/instructions/digital-deposits",
            "exact": true,
            "name": "Digital Deposits",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digital-deposit__index' */'@/pages/digital-deposit/index'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/digital-deposits/:id",
            "hideInMenu": true,
            "name": "Digital Deposits Details",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digital-deposit__detail' */'@/pages/digital-deposit/detail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/instructions/transfers",
            "name": "Transfers",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transfers__index' */'@/pages/transfers/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/instructions/transfers/:id",
            "hideInMenu": true,
            "name": "Transfers Details",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transfers__detail' */'@/pages/transfers/detail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/instructions/bill-payments",
            "name": "Bill Payments",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bill-payments__index' */'@/pages/bill-payments/index'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/bill-payments/new",
            "name": "New Bill Payment Instruction",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bill-payments__new' */'@/pages/bill-payments/new'), loading: LoadingComponent}),
            "hideInMenu": true
          },
          {
            "path": "/instructions/bill-payments/:id",
            "hideInMenu": true,
            "exact": true,
            "name": "Bill Payments Details",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bill-payments__detail' */'@/pages/bill-payments/detail'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/new-bill-payments",
            "name": "New Bill Payments",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new-bill-payments__index' */'@/pages/new-bill-payments/index'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/reconcilations",
            "name": "Digital Deposit Reconcilation",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reconcilations__index' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/reconcilations/index'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/exchanges",
            "name": "Exchanges",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exchanges__index' */'@/pages/exchanges/index'), loading: LoadingComponent})
          },
          {
            "path": "/instructions/exchanges/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exchanges__detail' */'@/pages/exchanges/detail'), loading: LoadingComponent}),
            "name": "ExchangeDetails",
            "layout": true,
            "hideChildren": true,
            "exact": true
          },
          {
            "path": "/instructions/fee-payouts/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fee-payout__detail' */'@/pages/fee-payout/detail'), loading: LoadingComponent}),
            "name": "FeePayoutDetails",
            "layout": true,
            "hideChildren": true,
            "exact": true
          },
          {
            "path": "/instructions/unionpay-remittance",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__remittance__unionpay' */'@/pages/remittance/unionpay'), loading: LoadingComponent}),
            "name": "UnionPay Remittance",
            "layout": true,
            "hideChildren": true,
            "exact": true
          },
          {
            "path": "/instructions/alipay-remittance",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__remittance__alipay' */'@/pages/remittance/alipay'), loading: LoadingComponent}),
            "name": "Alipay Remittance",
            "layout": true,
            "hideChildren": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/invoices",
        "name": "Invoices",
        "icon": "VerticalAlignTopOutlined",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invoices__index' */'@/pages/invoices/index'), loading: LoadingComponent})
      },
      {
        "icon": "CreditCardOutlined",
        "path": "/cms",
        "name": "Card & Credit",
        "routes": [
          {
            "path": "/cms/cards",
            "name": "Cards",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__cards__index' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/cms/cards/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/cms/card-account",
            "name": "Card Account",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__card-account__index' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/cms/card-account/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/cms/card-balances",
            "name": "Card Balances",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__card-balances__index' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/cms/card-balances/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/cms/card-repayment",
            "name": "Card Repayment",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__card-repayment__index' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/cms/card-repayment/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/cms/card-application",
            "name": "Card Application",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__card-application__index' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/cms/card-application/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/cms/card-pending-embossed",
            "name": "Card Pending Embossed",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__card-pending-embossed__index' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/cms/card-pending-embossed/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/cms/asset-value-config",
            "name": "Global LTV Config",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__asset-value-config__index' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/cms/asset-value-config/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/cms/card-products",
            "name": "Card Products",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__card-products__index' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/cms/card-products/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/cms/cards/:id/:userId",
            "exact": true,
            "name": "Cards Details",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__cards__details' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/cms/cards/details'), loading: LoadingComponent})
          },
          {
            "path": "/cms/transactions",
            "name": "Transactions",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__transaction__index' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/cms/transaction/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/cms/transactions/:id",
            "exact": true,
            "name": "Transaction Details",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__transaction__details' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/cms/transaction/details'), loading: LoadingComponent})
          }
        ]
      },
      {
        "path": "/securities",
        "name": "Securities",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__securities__index' */'@/pages/securities/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "icon": "MoneyCollectOutlined",
        "path": "/earn",
        "name": "Earn",
        "routes": [
          {
            "name": "Offers",
            "path": "/earn/offers",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__earn__offer__index' */'@/pages/earn/offer/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Subscriptions",
            "path": "/earn/subscriptions",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__earn__subscription__index' */'@/pages/earn/subscription/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Position",
            "path": "/earn/position",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__earn__position__index' */'@/pages/earn/position/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/alternatives",
        "name": "Alternatives",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__alternatives__index' */'@/pages/alternatives/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/alternatives/:id/:userId",
        "exact": true,
        "name": "Alternatives Details",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__alternatives__details' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/alternatives/details'), loading: LoadingComponent})
      },
      {
        "path": "/insurance",
        "name": "Insurance",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__insurance__index' */'@/pages/insurance/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/insurance/:id/:userId",
        "exact": true,
        "name": "Insurance Details",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__insurance__details' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/insurance/details'), loading: LoadingComponent})
      },
      {
        "path": "/nfts",
        "name": "Nft",
        "icon": "VerticalAlignTopOutlined",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__nft__index' */'@/pages/nft/index'), loading: LoadingComponent})
      },
      {
        "path": "/reporting",
        "name": "Reporting",
        "icon": "VerticalAlignTopOutlined",
        "routes": [
          {
            "path": "/reporting/new",
            "name": "New report",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Reporting__index' */'@/pages/Reporting/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reporting/accounting",
            "name": "New Accounting Report",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Reporting__accounting' */'@/pages/Reporting/accounting'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reporting/history",
            "name": "History",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Reporting__history' */'@/pages/Reporting/history'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reporting/accounting-history",
            "name": "Accounting Report History",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Reporting__accountingHistory' */'@/pages/Reporting/accountingHistory'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/accounting",
        "name": "Accounting",
        "icon": "Cash Inventory",
        "routes": [
          {
            "name": "World Pay",
            "path": "/accounting/world-pay",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__world-pay__index' */'@/pages/world-pay/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Journal Entries (Beta)",
            "path": "/accounting/journal-entries",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__journal-entries__index' */'@/pages/journal-entries/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Book Entries (Beta)",
            "path": "/accounting/book-entries",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__book-entries__index' */'@/pages/book-entries/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Ledger Account (Beta)",
            "path": "/accounting/ledger-account",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ledger-account__index' */'@/pages/ledger-account/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Bank Available Balance",
            "path": "/accounting/bank-available-balance",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bank-available-balance__index' */'@/pages/bank-available-balance/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "icon": "VerticalAlignTopOutlined",
        "name": "Fees & Commission",
        "path": "/cost-and-fees",
        "routes": [
          {
            "name": "Cost Management",
            "path": "/cost-and-fees/cost-management",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cost-and-fees__cost-management__index' */'@/pages/cost-and-fees/cost-management/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Markup Management",
            "path": "/cost-and-fees/fee-markup-management",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cost-and-fees__fee-markup-management__index' */'@/pages/cost-and-fees/fee-markup-management/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Fee Schedule",
            "path": "/cost-and-fees/fee-schedule",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cost-and-fees__fee-schedule__index' */'@/pages/cost-and-fees/fee-schedule/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New Fee Schedule",
            "path": "/cost-and-fees/fee-schedule-v2",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cost-and-fees__fee-schedule-v2__index' */'@/pages/cost-and-fees/fee-schedule-v2/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Users Fee Schedule",
            "path": "/cost-and-fees/users-fee-schedule",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cost-and-fees__users-fee-schedule__index' */'@/pages/cost-and-fees/users-fee-schedule/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/cost-and-fees/commission-management",
            "name": "Commission Management",
            "icon": "VerticalAlignTopOutlined",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__commission-payout__index' */'@/pages/commission-payout/index'), loading: LoadingComponent})
          },
          {
            "path": "/cost-and-fees/fee-setting-request",
            "name": "Fee Setting Request",
            "icon": "VerticalAlignTopOutlined",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fee-setting-request__index' */'@/pages/fee-setting-request/index'), loading: LoadingComponent})
          }
        ]
      },
      {
        "icon": "VerticalAlignTopOutlined",
        "path": "/transaction-monitoring",
        "name": "Transaction Monitoring",
        "routes": [
          {
            "name": "Fraud Transactions",
            "path": "/transaction-monitoring/fraud-transactions",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__card-fraud-system__index' */'@/pages/card-fraud-system/index'), loading: LoadingComponent})
          },
          {
            "name": "Card Rules Configuration",
            "path": "/transaction-monitoring/card-fraud-rules-configuration",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__card-fraud-system__configuration' */'@/pages/card-fraud-system/configuration'), loading: LoadingComponent})
          },
          {
            "name": "Transaction Monitoring Config",
            "path": "/transaction-monitoring/configuration",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user-threshold-config-override__index' */'@/pages/user-threshold-config-override/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "High Risk Cards Config",
            "path": "/transaction-monitoring/high-risk-card",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__card-fraud-system__high-risk-cards-configuration' */'@/pages/card-fraud-system/high-risk-cards-configuration'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "icon": "VerticalAlignTopOutlined",
        "path": "/rate-source",
        "name": "Rate Source",
        "routes": [
          {
            "name": "Configuration",
            "path": "/rate-source/configuration",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rate-source__configuration__index' */'@/pages/rate-source/configuration/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Rate Reference",
            "path": "/rate-source/rate-reference",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rate-source__rate-reference__index' */'@/pages/rate-source/rate-reference/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Rateboard",
            "path": "/rate-source/rate-board",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rate-source__rate-board__index' */'@/pages/rate-source/rate-board/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/purchase-order",
        "name": "Purchase Order",
        "icon": "VerticalAlignTopOutlined",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__purchase-order__index' */'@/pages/purchase-order/index'), loading: LoadingComponent})
      },
      {
        "path": "/tag-management",
        "name": "Tag Management",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tag-management__index' */'@/pages/tag-management/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/invitation-code",
        "name": "Invitation Code",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invitation-code__index' */'@/pages/invitation-code/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/relationship-managers",
        "name": "Relationship Managers",
        "icon": "user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__relationship-managers__index' */'@/pages/relationship-managers/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user-groups",
        "exact": true,
        "name": "User Groups",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cost-and-fees__user-group-management__index' */'@/pages/cost-and-fees/user-group-management/index'), loading: LoadingComponent})
      },
      {
        "path": "/user-groups/:id",
        "exact": true,
        "hideInMenu": true,
        "name": "User Group Details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cost-and-fees__user-group-management__details' */'@/pages/cost-and-fees/user-group-management/details'), loading: LoadingComponent})
      },
      {
        "name": "Treasury",
        "path": "/treasury",
        "routes": [
          {
            "path": "/treasury/overview",
            "name": "Summary",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__treasury__index' */'@/pages/treasury/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/treasury/digital",
            "name": "Digital Treasury",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__treasury__digital__index' */'@/pages/treasury/digital/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/treasury/digital-whitelist",
            "name": "Digital Address Whitelist",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__treasury__digital__whitelist' */'@/pages/treasury/digital/whitelist'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/super",
        "name": "Super Powers",
        "routes": [
          {
            "name": "Operators",
            "path": "/super/operators",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operators__index' */'@/pages/operators/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/users/:id",
        "exact": true,
        "hideInMenu": true,
        "name": "User Details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__users__details' */'@/pages/users/details'), loading: LoadingComponent})
      },
      {
        "path": "/transactions/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transactions__detail' */'@/pages/transactions/detail'), loading: LoadingComponent}),
        "layout": true,
        "hideChildren": true,
        "exact": true
      },
      {
        "path": "/",
        "exact": true,
        "redirect": "/dashboard"
      },
      {
        "path": "/account/settings",
        "hideInMenu": true,
        "exact": true,
        "name": "Account Settings",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account-settings__index' */'@/pages/account-settings/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/cash-inventory",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cash-inventory' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/cash-inventory'), loading: LoadingComponent}),
        "name": "Cash Inventory",
        "exact": true
      },
      {
        "icon": "VerticalAlignTopOutlined",
        "path": "/transaction-monitoring",
        "name": "Transaction Monitoring",
        "routes": [
          {
            "name": "Configuration",
            "path": "/transaction-monitoring/configuration",
            "exact": true
          }
        ]
      },
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/login'), loading: LoadingComponent}),
        "layout": false,
        "hideChildren": true,
        "exact": true
      },
      {
        "path": "/cases/:id",
        "exact": true,
        "hideInMenu": true,
        "name": "Case Details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__case-management__detail' */'@/pages/case-management/detail'), loading: LoadingComponent})
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'C:/Code/Unisoft/utx/projects/utx-ops-web/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
