import { RequestData } from '@ant-design/pro-table';
import { request } from 'umi';

export interface UsersListItem {
  signupDate: Date;
  id: string;
  name: string;
  status: string;
  accountNumber: string;
  relationshipManager: {
    id: string;
    name: string;
  };
  vendor: {
    id: number;
    name: string;
  };
  email: string;
  phone: string;
  countryCode: number;
}

export enum AccountType {
  PERSONAL = 'PERSONAL',
  CORPORATE = 'CORPORATE',
  COBRAND = 'COBRAND'
}

export interface JwtToken {
  id: string;
  iat: number;
  exp: number;
}

export interface SolutionApplicationFile {
  fileList: Array<{ uid?: string; name?: string; url?: string; status?: string; response?: { url?: string } }>;
}

export interface ClientDocumentDetail {
  id: string;
  name?: string;
  fileName?: string;
  documentKey?: string;
  documentUrl?: string;
}

export interface ClientDocument {
  id?: string;
  documentType?: string;
  status?: string;
  details?: Array<ClientDocumentDetail>;
}

export async function queryMyRoutes() {
  return request<any>('/~/routes');
}

export async function query(params: any): Promise<RequestData<UsersListItem>> {
  if (params.signupDate) {
    params.fromDate = params.signupDate[0];
    params.toDate = params.signupDate[1];
    delete params.signupDate;
  }
  return request<RequestData<UsersListItem>>('/users', { params });
}

export async function queryAccountActivation(params: any): Promise<RequestData<UsersListItem>> {
  if (params.signupDate) {
    params.fromDate = params.signupDate[0];
    params.toDate = params.signupDate[1];
    delete params.signupDate;
  }
  params.status = 'PENDING_ACTIVATION';
  const usersListItem = await request<RequestData<UsersListItem>>('/users', { params });
  return usersListItem;
}
