import { intentDetailUrlParser } from '@/services/instructions';
import { useDebounceFn } from '@umijs/hooks';
import { Col, Input, Row, Spin, notification } from 'antd';
import React, { useState } from 'react';
import { Link, request, useRequest } from 'umi';

export type Props = {
  onChange?: (value: string) => any | ((value: string, option?: { current: string[] }) => Promise<void>);
  debounceInterval?: number;
  intentRefId?: string;
  style?: any;
  overWriteText?: string;
};

export const InstructionSelect = (props: Props) => {
  const { onChange, debounceInterval = 300 } = props;

  const [searchText, setSearchText] = useState<string>();

  const { loading, run } = useRequest(
    (params) =>
      request('/instructions/search', {
        params: params,
        getResponse: true
      }),
    {
      defaultParams: {
        text: undefined
      } as any,
      manual: true,
      onSuccess: (data) => {
        setSearchText(undefined);
        if (data.message) {
          notification.error({
            message: data.message
          });
        } else {
          const url = intentDetailUrlParser({ type: data.intent, refId: data.refId });
          console.log('InstructionSelect: ', `${url}/${data.intentId}`);
          if (!!url) {
            onChange && onChange(`${url}/${data.intentId}`);
          }
        }
      }
    }
  );

  const { run: searchIntent } = useDebounceFn((input: string) => {
    run({
      text: input.trim()
    });
  }, debounceInterval);

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != undefined && e.target.value.trim() != '' && e.target.value.length >= 10) {
      setSearchText(e.target.value);
      searchIntent(e.target.value);
    }
  };

  return (
    <Row>
      <Col span={20}>
        <Input value={searchText} disabled={loading} onInput={onInput} placeholder="Instruction Ref #" />
      </Col>
      <Col span={4}>{loading && <Spin style={{ marginLeft: 8 }} />}</Col>
    </Row>
  );
};

export const InstructionHyperlink = (props: Props) => {
  if (!props.intentRefId) {
    return <></>;
  }
  const [intentInfo, setIntentInfo] = useState<{
    intent: string;
    refId: string;
    intentId: string;
    url: string;
    displayTitle: string;
  }>();

  useRequest(
    () =>
      request('/instructions/search', {
        params: {
          text: props.intentRefId
        },
        getResponse: true
      }),
    {
      onSuccess: (data) => {
        if (data.message) {
          notification.error({
            message: data.message
          });
        } else {
          const url = intentDetailUrlParser({ type: data.intent, refId: data?.refId });
          data.displayTitle = `${data.intent} - ${data?.refId}`;
          data.url = url ? `${url}/${data.intentId}` : '#';
          setIntentInfo(data);
        }
      }
    }
  );

  return (
    <Link to={`${intentInfo?.url}`} target="_blank" style={props.style ? props.style : {}}>
      {props?.overWriteText ? props?.overWriteText : intentInfo?.displayTitle}
    </Link>
  );
};
