import React from 'react';
import { useModel } from 'umi';

export enum ModuleName {
  '*' = '*',
  'USER' = 'USER',
  'SECURITIES' = 'SECURITIES',
  'STATEMENTS' = 'STATEMENTS',
  'FIAT' = 'FIAT',
  'DIGITAL' = 'DIGITAL',
  'INSURANCE' = 'INSURANCE',
  'COMMODITY' = 'COMMODITY',
  'TREASURY' = 'TREASURY',
  'OPERATOR' = 'OPERATOR',
  'PENDING_PAYMENT' = 'PENDING_PAYMENT',
  'KYC' = 'KYC',
  'GENERAL_INSTRUCTION' = 'GENERAL_INSTRUCTION',
  'FEE_PAYOUT' = 'FEE_PAYOUT',
  'TRANSFER' = 'TRANSFER',
  'BILL_PAYMENT' = 'BILL_PAYMENT',
  'TAG_MANAGEMENT' = 'TAG_MANAGEMENT',
  'INVITATION_CODE' = 'INVITATION_CODE',
  'BENEFICIARIES' = 'BENEFICIARIES',
  'REPORTING' = 'REPORTING',
  'ACCOUNTING_REPORT' = 'ACCOUNTING_REPORT',
  'GENERAL_FORM' = 'GENERAL_FORM',
  'CMS_CARD' = 'CMS_CARD',
  'CMS_CREDIT' = 'CMS_CREDIT',
  'CMS' = 'CMS',
  'ALTERNATIVES' = 'ALTERNATIVES',
  'VENDOR' = 'VENDOR',
  'RELATIONSHIP_MANAGER' = 'RELATIONSHIP_MANAGER',
  'USER_THRESHOLD_CONFIG_OVERRIDE' = 'USER_THRESHOLD_CONFIG_OVERRIDE',
  'CARD_FRAUD_SYSTEM' = 'CARD_FRAUD_SYSTEM',
  'REPAYMENT' = 'REPAYMENT',
  'EXCHANGE' = 'EXCHANGE',
  'CLIENT_DOCUMENT' = 'CLIENT_DOCUMENT',
  'ALL_INSTRUCTION' = 'ALL_INSTRUCTION',
  'SCHEDULED_INSTRUCTION' = 'SCHEDULED_INSTRUCTION',
  'WALLET' = 'WALLET',
  'REVENUE' = 'REVENUE',
  'CASE' = 'CASE',
  'RATE_SOURCE' = 'RATE_SOURCE',
  'REMARK' = 'REMARK',
  'NFT' = 'NFT',
  'PURCHASE_ORDER' = 'PURCHASE_ORDER',
  'EARN' = 'EARN',
  'NAME_CHECK' = 'NAME_CHECK',
  'DEPOSIT_ADDRESSES' = 'DEPOSIT_ADDRESSES',
  'BANK_ACCOUNTS' = 'BANK_ACCOUNTS',
  'PAYMENT_DETAILS' = 'PAYMENT_DETAILS',
  'SOLUTION' = 'SOLUTION',
  'CASH_IN_OUT' = 'CASH_IN_OUT',
  'WORLD_PAY' = 'WORLD_PAY',
  'BOOK_ENTRIES' = 'BOOK_ENTRIES',
  'LEDGER_ACCOUNT' = 'LEDGER_ACCOUNT',
  'JOURNAL_ENTRIES' = 'JOURNAL_ENTRIES',
  'BANK_AVAILABLE_BALANCE' = 'BANK_AVAILABLE_BALANCE',
  'OD_ACCOUNT' = 'OD_ACCOUNT',
  'APPROVAL_REQUEST' = 'APPROVAL_REQUEST',
  'UNIONPAY_REMITTANCE' = 'UNIONPAY_REMITTANCE',
  'ALIPAY_REMITTANCE' = 'ALIPAY_REMITTANCE'
}

export enum PermissionType {
  'ROLE' = 'ROLE',
  'ACTION' = 'ACTION'
}

export enum PermissionRole {
  '*' = '*',
  'READ' = 'READ',
  'WRITE' = 'WRITE'
}

export enum PermissionVerbAction {
  'VIEW:*' = 'VIEW:*',
  'ACTION:*' = 'ACTION:*',
  'VIEW:PENDING_PAYMENT' = 'VIEW:PENDING_PAYMENT',
  'VIEW:KYC' = 'VIEW:KYC',
  'VIEW:BENEFICIARIES' = 'VIEW:BENEFICIARIES',
  'VIEW:CMS_CARD_PRODUCT' = 'VIEW:CMS_CARD_PRODUCT',
  'VIEW:CMS_CARD_TRANSACTIONS' = 'VIEW:CMS_CARD_TRANSACTIONS',
  'VIEW:CMS_CARD_REPAYMENT' = 'VIEW:CMS_CARD_REPAYMENT',
  'VIEW:VENDOR' = 'VIEW:VENDOR',
  'VIEW:GENERAL_INSTRUCTION' = 'VIEW:GENERAL_INSTRUCTION',
  'VIEW:FIAT_WITHDRAW' = 'VIEW:FIAT_WITHDRAW',
  'VIEW:FIAT_DEPOSIT' = 'VIEW:FIAT_DEPOSIT',
  'VIEW:DIGITAL_WITHDRAW' = 'VIEW:DIGITAL_WITHDRAW',
  'VIEW:DIGITAL_DEPOSIT' = 'VIEW:DIGITAL_DEPOSIT',
  'VIEW:DIGITAL_DEPOSIT_PENDING' = 'VIEW:DIGITAL_DEPOSIT_PENDING',
  'VIEW:TRANSFER' = 'VIEW:TRANSFER',
  'VIEW:BILL_PAYMENT' = 'VIEW:BILL_PAYMENT',
  'VIEW:SECURITIES' = 'VIEW:SECURITIES',
  'VIEW:STATEMENTS' = 'VIEW:STATEMENTS',
  'VIEW:ALTERNATIVES' = 'VIEW:ALTERNATIVES',
  'VIEW:ALTERNATIVES_OVERVIEW' = 'VIEW:ALTERNATIVES_OVERVIEW',
  'VIEW:INSURANCE' = 'VIEW:INSURANCE',
  'VIEW:INSURANCE_OVERVIEW' = 'VIEW:INSURANCE_OVERVIEW',
  'VIEW:INVITATION_CODE' = 'VIEW:INVITATION_CODE',
  'VIEW:TREASURY_OVERVIEW' = 'VIEW:TREASURY_OVERVIEW',
  'VIEW:GENERAL_FORM_OVERVIEW' = 'VIEW:GENERAL_FORM_OVERVIEW',
  'VIEW:TREASURY_DIGITAL_WHITELIST' = 'VIEW:TREASURY_DIGITAL_WHITELIST',
  'VIEW:TREASURY_DIGITAL_TRANSFER' = 'VIEW:TREASURY_DIGITAL_TRANSFER',
  'VIEW:CASH_INVENTORY' = 'VIEW:CASH_INVENTORY',
  'VIEW:OPERATOR' = 'VIEW:OPERATOR',
  'VIEW:USER' = 'VIEW:USER',
  'VIEW:USER_CONTACT' = 'VIEW:USER_CONTACT',
  'VIEW:REPORTING' = 'VIEW:REPORTING',
  'VIEW:ACCOUNTING_REPORT' = 'VIEW:ACCOUNTING_REPORT',
  'VIEW:REVENUE' = 'VIEW:REVENUE',
  'VIEW:CMS_CARD' = 'VIEW:CMS_CARD',
  'VIEW:CMS_CARD_ISSUE' = 'VIEW:CMS_CARD_ISSUE',
  'VIEW:CMS_CARD_APPLICATION' = 'VIEW:CMS_CARD_APPLICATION',
  'VIEW:CMS_PENDING_EMBOSSED' = 'VIEW:CMS_PENDING_EMBOSSED',
  'VIEW:CMS_CARD_INFO' = 'VIEW:CMS_CARD_INFO',
  'VIEW:USER_THRESHOLD_CONFIG_OVERRIDE' = 'VIEW:USER_THRESHOLD_CONFIG_OVERRIDE',
  'VIEW:CARD_FRAUD_SYSTEM' = 'VIEW:CARD_FRAUD_SYSTEM',
  'VIEW:RELATIONSHIP_MANAGER' = 'VIEW:RELATIONSHIP_MANAGER',
  'VIEW:ASSET_VALUE_CONFIG' = 'VIEW:ASSET_VALUE_CONFIG',
  'VIEW:ASSET_VALUE_CONFIG_OVERRIDE' = 'VIEW:ASSET_VALUE_CONFIG_OVERRIDE',
  'VIEW:CMS_CARD_FIXED_LIMIT' = 'VIEW:CMS_CARD_FIXED_LIMIT',
  'VIEW:EXCHANGE' = 'VIEW:EXCHANGE',
  'VIEW:CLIENT_DOCUMENT' = 'VIEW:CLIENT_DOCUMENT',
  'VIEW:RATE_SOURCE' = 'VIEW:RATE_SOURCE',
  'VIEW:INVOICE' = 'VIEW:INVOICE',
  'VIEW:EARN_OFFER' = 'VIEW:EARN_OFFER',
  'VIEW:EARN_POSITION' = 'VIEW:EARN_POSITION',
  'VIEW:EARN_SUBSCRIPTION' = 'VIEW:EARN_SUBSCRIPTION',
  'VIEW:PURCHASE_ORDER' = 'VIEW:PURCHASE_ORDER',
  'VIEW:RATE_REFERENCE' = 'VIEW:RATE_REFERENCE',
  'VIEW:USER_RISK_PROFILE' = 'VIEW:USER_RISK_PROFILE',
  'VIEW:FEE_COST' = 'VIEW:FEE_COST',
  'VIEW:FEE_MARKUP' = 'VIEW:FEE_MARKUP',
  'VIEW:FEE_PAYOUT' = 'VIEW:FEE_PAYOUT',
  'VIEW:USER_GROUP' = 'VIEW:USER_GROUP',
  'VIEW:FEE_SETTING_PREVIEW' = 'VIEW:FEE_SETTING_PREVIEW',
  'VIEW:ALL_INSTRUCTION' = 'VIEW:ALL_INSTRUCTION',
  'VIEW:SCHEDULED_INSTRUCTION' = 'VIEW:SCHEDULED_INSTRUCTION',
  'VIEW:REMARK' = 'VIEW:REMARK',
  'VIEW:TAG_MANAGEMENT' = 'VIEW:TAG_MANAGEMENT',
  'VIEW:CASE' = 'VIEW:CASE',
  'VIEW:NFT' = 'VIEW:NFT',
  'VIEW:WALLET_ACCOUNTS' = 'VIEW:WALLET_ACCOUNTS',
  'VIEW:FEE_SCHEDULE' = 'VIEW:FEE_SCHEDULE',
  'VIEW:NAME_CHECK' = 'VIEW:NAME_CHECK',
  'VIEW:BANK_ACCOUNTS' = 'VIEW:BANK_ACCOUNTS',
  'VIEW:USER_WELCOME_EMAIL' = 'VIEW:USER_WELCOME_EMAIL',
  'VIEW:SOLUTION_APPLICATION' = 'VIEW:SOLUTION_APPLICATION',
  'VIEW:RATE_BOARD' = 'VIEW:RATE_BOARD',
  'VIEW:CASH_IN_OUT' = 'VIEW:CASH_IN_OUT',
  'VIEW:WORLD_PAY' = 'VIEW:WORLD_PAY',
  'VIEW:BOOK_ENTRIES' = 'VIEW:BOOK_ENTRIES',
  'VIEW:LEDGER_ACCOUNT' = 'VIEW:LEDGER_ACCOUNT',
  'VIEW:JOURNAL_ENTRIES' = 'VIEW:JOURNAL_ENTRIES',
  'VIEW:BANK_AVAILABLE_BALANCE' = 'VIEW:BANK_AVAILABLE_BALANCE',
  'VIEW:COMMISSION_MANAGEMENT' = 'VIEW:COMMISSION_MANAGEMENT',
  'VIEW:OD_ACCOUNT' = 'VIEW:OD_ACCOUNT',
  'VIEW:UNIONPAY_REMITTANCE' = 'VIEW:UNIONPAY_REMITTANCE',
  'VIEW:ALIPAY_REMITTANCE' = 'VIEW:ALIPAY_REMITTANCE',

  'ACTION:EDIT_ACCOUNT_ACTIVATION' = 'ACTION:EDIT_ACCOUNT_ACTIVATION',

  // GENERAL_INSTRUCTION
  'ACTION:IMPORT_GENERAL_INSTRUCTION' = 'ACTION:IMPORT_GENERAL_INSTRUCTION',
  'ACTION:UPLOAD_GENERAL_INSTRUCTION' = 'ACTION:UPLOAD_GENERAL_INSTRUCTION',
  'ACTION:CREATE_GENERAL_INSTRUCTION' = 'ACTION:CREATE_GENERAL_INSTRUCTION',
  'ACTION:UPDATE_GENERAL_INSTRUCTION' = 'ACTION:UPDATE_GENERAL_INSTRUCTION',
  'ACTION:PROCESS_GENERAL_INSTRUCTION' = 'ACTION:PROCESS_GENERAL_INSTRUCTION',
  'ACTION:COMPLETE_GENERAL_INSTRUCTION' = 'ACTION:COMPLETE_GENERAL_INSTRUCTION',
  'ACTION:SUBMIT_GENERAL_INSTRUCTION' = 'ACTION:SUBMIT_GENERAL_INSTRUCTION',
  'ACTION:BATCH_UPDATE_GENERAL_INSTRUCTION' = 'ACTION:BATCH_UPDATE_GENERAL_INSTRUCTION',
  'ACTION:BATCH_PROCESS_GENERAL_INSTRUCTION' = 'ACTION:BATCH_PROCESS_GENERAL_INSTRUCTION',
  'ACTION:BATCH_COMPLETE_GENERAL_INSTRUCTION' = 'ACTION:BATCH_COMPLETE_GENERAL_INSTRUCTION',
  'ACTION:BATCH_SUBMIT_GENERAL_INSTRUCTION' = 'ACTION:BATCH_SUBMIT_GENERAL_INSTRUCTION',
  'ACTION:REPROCESS_GENERAL_INSTRUCTION' = 'ACTION:REPROCESS_GENERAL_INSTRUCTION',
  'ACTION:CANCEL_GENERAL_INSTRUCTION' = 'ACTION:CANCEL_GENERAL_INSTRUCTION',
  'ACTION:REJECT_GENERAL_INSTRUCTION' = 'ACTION:REJECT_GENERAL_INSTRUCTION',
  'ACTION:APPROVE_GENERAL_INSTRUCTION' = 'ACTION:APPROVE_GENERAL_INSTRUCTION',
  'ACTION:DOWNLOAD_GENERAL_INSTRUCTION' = 'ACTION:DOWNLOAD_GENERAL_INSTRUCTION',
  'ACTION:DOWNLOAD_PENDING_INSTRUCTION_REPORT' = 'ACTION:DOWNLOAD_PENDING_INSTRUCTION_REPORT',
  // KYC
  'ACTION:UPLOAD_KYC_DOCUMENT' = 'ACTION:UPLOAD_KYC_DOCUMENT',
  'ACTION:PROCESS_KYC_DOCUMENT' = 'ACTION:PROCESS_KYC_DOCUMENT',
  'ACTION:PROCESS_KYC' = 'ACTION:PROCESS_KYC',
  'ACTION:EDIT_KYC_LEVEL' = 'ACTION:EDIT_KYC_LEVEL',
  'ACTION:IMPERSONATE' = 'ACTION:IMPERSONATE',
  'ACTION:CREATE_INVITATION_CODE' = 'ACTION:CREATE_INVITATION_CODE',
  'ACTION:CREATE_USER' = 'ACTION:CREATE_USER',
  'ACTION:EDIT_USER_RISK_PROFILE' = 'ACTION:EDIT_USER_RISK_PROFILE',
  'ACTION:EDIT_USER_PROFILE' = 'ACTION:EDIT_USER_PROFILE',
  'ACTION:EDIT_USER_CONTACT' = 'ACTION:EDIT_USER_CONTACT',
  'ACTION:EDIT_USER_SUMMARY' = 'ACTION:EDIT_USER_SUMMARY',
  'ACTION:EDIT_USER_STATEMENT_OPTIONS' = 'ACTION:EDIT_USER_STATEMENT_OPTIONS',
  'ACTION:EDIT_DEPOSIT_ADDRESS' = 'ACTION:EDIT_DEPOSIT_ADDRESS',
  'ACTION:RESET_USER_PASSWORD' = 'ACTION:RESET_USER_PASSWORD',
  'ACTION:RESET_2FA' = 'ACTION:RESET_2FA',
  'ACTION:SEND_EMAIL_TO_USER' = 'ACTION:SEND_EMAIL_TO_USER',
  'ACTION:EDIT_USER_BENEFICIARIES' = 'ACTION:EDIT_USER_BENEFICIARIES',
  'ACTION:EDIT_USER_STATUS' = 'ACTION:EDIT_USER_STATUS',
  'ACTION:PROCESS_FIAT_DEPOSIT' = 'ACTION:PROCESS_FIAT_DEPOSIT',
  'ACTION:PROCESS_FIAT_WITHDRAW' = 'ACTION:PROCESS_FIAT_WITHDRAW',
  'ACTION:UPDATE_FIAT_DEPOSIT' = 'ACTION:UPDATE_FIAT_DEPOSIT',
  'ACTION:UPDATE_FIAT_WITHDRAW' = 'ACTION:UPDATE_FIAT_WITHDRAW',
  'ACTION:PROCESS_DIGITAL_WITHDRAW' = 'ACTION:PROCESS_DIGITAL_WITHDRAW',
  'ACTION:PROCESS_DIGITAL_DEPOSIT' = 'ACTION:PROCESS_DIGITAL_DEPOSIT',
  'ACTION:PROCESS_TRANSFER' = 'ACTION:PROCESS_TRANSFER',
  'ACTION:CREATE_BILL_PAYMENT' = 'ACTION:CREATE_BILL_PAYMENT',
  'ACTION:UPDATE_BILL_PAYMENT' = 'ACTION:UPDATE_BILL_PAYMENT',
  'ACTION:PROCESS_BILL_PAYMENT' = 'ACTION:PROCESS_BILL_PAYMENT',
  'ACTION:COMPLETE_BILL_PAYMENT' = 'ACTION:COMPLETE_BILL_PAYMENT',
  'ACTION:CANCEL_BILL_PAYMENT' = 'ACTION:CANCEL_BILL_PAYMENT',
  'ACTION:IMPORT_SECURITIES' = 'ACTION:IMPORT_SECURITIES',
  'ACTION:IMPORT_STATEMENTS' = 'ACTION:IMPORT_STATEMENTS',
  'ACTION:CREATE_SECURITIES_ACCOUNT' = 'ACTION:CREATE_SECURITIES_ACCOUNT',
  'ACTION:EDIT_SECURITIES_MANUAL' = 'ACTION:EDIT_SECURITIES_MANUAL',
  'ACTION:CREATE_INSURANCE_POLICY' = 'ACTION:CREATE_INSURANCE_POLICY',
  'ACTION:EDIT_INSURANCE_POLICY' = 'ACTION:EDIT_INSURANCE_POLICY',
  'ACTION:CREATE_TREASURY_DIGITAL_TRANSFER' = 'ACTION:CREATE_TREASURY_DIGITAL_TRANSFER',
  'ACTION:CREATE_OPERATOR' = 'ACTION:CREATE_OPERATOR',
  'ACTION:EDIT_OPERATOR_YOTP' = 'ACTION:EDIT_OPERATOR_YOTP',
  'ACTION:EDIT_OPERATOR_PERMISSION' = 'ACTION:EDIT_OPERATOR_PERMISSION',
  'ACTION:EDIT_OPERATOR_VENDOR' = 'ACTION:EDIT_OPERATOR_VENDOR',
  'ACTION:UNLOCK_OPERATOR' = 'ACTION:UNLOCK_OPERATOR',
  'ACTION:CREATE_CMS_CARD_PRODUCT' = 'ACTION:CREATE_CMS_CARD_PRODUCT',
  'ACTION:CMS_CARD_CANCEL' = 'ACTION:CMS_CARD_CANCEL',
  'ACTION:CMS_CARD_ISSUE' = 'ACTION:CMS_CARD_ISSUE',
  'ACTION:CMS_CREDIT_ENABLE_SECURED' = 'ACTION:CMS_CREDIT_ENABLE_SECURED',
  'ACTION:CMS_CARD_MAILED' = 'ACTION:CMS_CARD_MAILED',
  'ACTION:CREATE_RELATIONSHIP_MANAGER' = 'ACTION:CREATE_RELATIONSHIP_MANAGER',
  'ACTION:EDIT_RELATIONSHIP_MANAGER' = 'ACTION:EDIT_RELATIONSHIP_MANAGER',
  'ACTION:CMS_CARD_ACTIVATION' = 'ACTION:CMS_CARD_ACTIVATION',
  'ACTION:CMS_CARD_REPAYMENT' = 'ACTION:CMS_CARD_REPAYMENT',
  'ACTION:CMS_CARD_FIXED_LIMIT' = 'ACTION:CMS_CARD_FIXED_LIMIT',
  'ACTION:TOPUP_CREDIT' = 'ACTION:TOPUP_CREDIT',
  'ACTION:CMS_CARD_DAILY_LIMIT' = 'ACTION:CMS_CARD_DAILY_LIMIT',
  'ACTION:CMS_CARD_SUSPENSION' = 'ACTION:CMS_CARD_SUSPENSION',
  'ACTION:CMS_CARD_CHANGE_PASSCODE' = 'ACTION:CMS_CARD_CHANGE_PASSCODE',
  'ACTION:CMS_CARD_CHANGE_EMBOSSED_NAME' = 'ACTION:CMS_CARD_CHANGE_EMBOSSED_NAME',
  'ACTION:CMS_CARD_APPLICATION' = 'ACTION:CMS_CARD_APPLICATION',
  'ACTION:CMS_CARD_ANNUAL_FEE' = 'ACTION:CMS_CARD_ANNUAL_FEE',
  'ACTION:CMS_CREDIT_SET_CREDIT_LIMIT' = 'ACTION:CMS_CREDIT_SET_CREDIT_LIMIT',
  'ACTION:CMS_CREDIT_PLATFORM_REPAYMENT' = 'ACTION:CMS_CREDIT_PLATFORM_REPAYMENT',
  'ACTION:EDIT_ALTERNATIVES' = 'ACTION:EDIT_ALTERNATIVES',
  'ACTION:EDIT_CMS_CARD_PHONE' = 'ACTION:EDIT_CMS_CARD_PHONE',
  'ACTION:EDIT_CMS_AUTOPAY_ASSETS' = 'ACTION:EDIT_CMS_AUTOPAY_ASSETS',
  'ACTION:EDIT_CMS_CARD_LOCK' = 'ACTION:EDIT_CMS_CARD_LOCK',
  'ACTION:EDIT_CMS_CARD_UNLOCK' = 'ACTION:EDIT_CMS_CARD_UNLOCK',
  'ACTION:EDIT_CLIENT_DOCUMENT' = 'ACTION:EDIT_CLIENT_DOCUMENT',
  'ACTION:CREATE_CLIENT_DOCUMENT' = 'ACTION:CREATE_CLIENT_DOCUMENT',
  'ACTION:CREATE_INVOICE' = 'ACTION:CREATE_INVOICE',
  'ACTION:EDIT_INVOICE' = 'ACTION:EDIT_INVOICE',
  'ACTION:EDIT_PURCHASE_ORDER' = 'ACTION:EDIT_PURCHASE_ORDER',
  'ACTION:CREATE_PURCHASE_ORDER' = 'ACTION:CREATE_PURCHASE_ORDER',
  'ACTION:EDIT_CMS_CARD_APPLICATION_CANCEL' = 'ACTION:EDIT_CMS_CARD_APPLICATION_CANCEL',
  'ACTION:CREATE_TRANSFER' = 'ACTION:CREATE_TRANSFER',
  'ACTION:EXCHANGE' = 'ACTION:EXCHANGE',
  'ACTION:EDIT_WALLET_OVERDRAFT' = 'ACTION:EDIT_WALLET_OVERDRAFT',
  'ACTION:EDIT_USER_GROUP' = 'ACTION:EDIT_USER_GROUP',
  'ACTION:EDIT_FEE_COST' = 'ACTION:EDIT_FEE_COST',
  'ACTION:EDIT_FEE_MARKUP' = 'ACTION:EDIT_FEE_MARKUP',
  'ACTION:DELETE_FEE' = 'ACTION:DELETE_FEE',
  'ACTION:CANCEL_FEE' = 'ACTION:CANCEL_FEE',
  'ACTION:CHARGE_FEE' = 'ACTION:CHARGE_FEE',
  'ACTION:UPDATE_FEE' = 'ACTION:UPDATE_FEE',
  'ACTION:EDIT_RATE_SOURCE' = 'ACTION:EDIT_RATE_SOURCE',
  'ACTION:IMPORT_INSURANCE_POLICY' = 'ACTION:IMPORT_INSURANCE_POLICY',
  'ACTION:EDIT_REMARK' = 'ACTION:EDIT_REMARK',
  'ACTION:ASSIGN_BANK_ACCOUNT' = 'ACTION:ASSIGN_BANK_ACCOUNT',
  'ACTION:ENABLE_BANK_ACCOUNT' = 'ACTION:ENABLE_BANK_ACCOUNT',
  'ACTION:DISABLE_BANK_ACCOUNT' = 'ACTION:DISABLE_BANK_ACCOUNT',
  'ACTION:PROCESS_BANK_ACCOUNT' = 'ACTION:PROCESS_BANK_ACCOUNT',
  'ACTION:ENABLE_AUTO_CREDIT' = 'ACTION:ENABLE_AUTO_CREDIT',
  'ACTION:DISABLE_AUTO_CREDIT' = 'ACTION:DISABLE_AUTO_CREDIT',
  'ACTION:UPDATE_AUTO_CREDIT' = 'ACTION:UPDATE_AUTO_CREDIT',
  'ACTION:CREATE_TAG' = 'ACTION:CREATE_TAG',
  'ACTION:RENAME_TAG' = 'ACTION:RENAME_TAG',
  'ACTION:CASE_CHANGE_STATUS' = 'ACTION:CASE_CHANGE_STATUS',
  'ACTION:CASE_CHANGE_RESOLUTION' = 'ACTION:CASE_CHANGE_RESOLUTION',
  'ACTION:SET_USER_GROUPS' = 'ACTION:SET_USER_GROUPS',
  'ACTION:CMS_CARD_TRANSACTIONS_MARK_DISPUTE' = 'ACTION:CMS_CARD_TRANSACTIONS_MARK_DISPUTE',
  'ACTION:CMS_CARD_CHANGE_NOTIFICATION' = 'ACTION:CMS_CARD_CHANGE_NOTIFICATION',
  'ACTION:CARD_FRAUD_SYSTEM' = 'ACTION:CARD_FRAUD_SYSTEM',
  'ACTION:REVIEW_CRA_FORM' = 'ACTION:REVIEW_CRA_FORM',
  'ACTION:CMS_IMPORT_UNISSUED_CARD' = 'ACTION:CMS_IMPORT_UNISSUED_CARD',
  'ACTION:CMS_APPLY_LOAN_RATIO_CONFIG' = 'ACTION:CMS_APPLY_LOAN_RATIO_CONFIG',
  'ACTION:ADD_ASSET_VALUE_CONFIG_OVERRIDE' = 'ACTION:ADD_ASSET_VALUE_CONFIG_OVERRIDE',
  'ACTION:SAVE_ASSET_VALUE_CONFIG_OVERRIDE' = 'ACTION:SAVE_ASSET_VALUE_CONFIG_OVERRIDE',
  'ACTION:CREATE_ALTERNATIVES' = 'ACTION:CREATE_ALTERNATIVES',
  'ACTION:CREATE_WALLET_ACCOUNT' = 'ACTION:CREATE_WALLET_ACCOUNT',
  'ACTION:EDIT_WALLET_ACCOUNT' = 'ACTION:EDIT_WALLET_ACCOUNT',
  'ACTION:CREATE_TREASURY_DIGITAL_WHITELIST' = 'ACTION:CREATE_TREASURY_DIGITAL_WHITELIST',
  'ACTION:CREATE_EARN_OFFER' = 'ACTION:CREATE_EARN_OFFER',
  'ACTION:EDIT_EARN_OFFER' = 'ACTION:EDIT_EARN_OFFER',
  'ACTION:EDIT_NAME_CHECK' = 'ACTION:EDIT_NAME_CHECK',
  'ACTION:REFRESH_DEPOSIT_ADDRESSES' = 'ACTION:REFRESH_DEPOSIT_ADDRESSES',
  'ACTION:CREATE_CASH_INVENTORY' = 'ACTION:CREATE_CASH_INVENTORY',
  'ACTION:UPDATE_PAYMENT_DETAILS' = 'ACTION:UPDATE_PAYMENT_DETAILS',
  'ACTION:UPDATE_SOLUTION_APPLICATION_STATUS' = 'ACTION:UPDATE_SOLUTION_APPLICATION_STATUS',
  'ACTION:APPLY_SOLUTION_APPLICATION' = 'ACTION:APPLY_SOLUTION_APPLICATION',
  'ACITON:EDIT_RATE_BOARD' = 'ACITON:EDIT_RATE_BOARD',
  'ACTION:REQUEST_EXCHANGE' = 'ACTION:REQUEST_EXCHANGE',
  'ACTION:APPROVE_EXCHANGE' = 'ACTION:APPROVE_EXCHANGE',
  'ACTION:CANCEL_EXCHANGE' = 'ACTION:CANCEL_EXCHANGE',
  'ACTION:QUOTE_EXCHANGE' = 'ACTION:QUOTE_EXCHANGE',
  'ACTION:EXECUTE_EXCHANGE' = 'ACTION:EXECUTE_EXCHANGE',
  'ACTION:EDIT_SOLUTION_APPLICATION' = 'ACTION:EDIT_SOLUTION_APPLICATION',
  'ACTION:APPROVE_SOLUTION_APPLICATION' = 'ACTION:APPROVE_SOLUTION_APPLICATION',
  'ACTION:EDIT_BANK_ACCOUNTS_WHITELIST' = 'ACTION:EDIT_BANK_ACCOUNTS_WHITELIST',
  'ACTION:EDIT_EARN_SUBSCRIPTION' = 'ACTION:EDIT_EARN_SUBSCRIPTION',
  'ACTION:CREATE_EARN_SUBSCRIPTION' = 'ACTION:CREATE_EARN_SUBSCRIPTION',
  'ACTION:APPROVE_REQUESTED_EARN_SUBSCRIPTION' = 'ACTION:APPROVE_REQUESTED_EARN_SUBSCRIPTION',
  'ACTION:REJECT_REQUESTED_EARN_SUBSCRIPTION' = 'ACTION:REJECT_REQUESTED_EARN_SUBSCRIPTION',
  'ACTION:CANCEL_SCHEDULED_INSTRUCTION' = 'ACTION:CANCEL_SCHEDULED_INSTRUCTION',
  'ACTION:PROCESS_WORLD_PAY_SETTLEMENT' = 'ACTION:PROCESS_WORLD_PAY_SETTLEMENT',
  'ACTION:PROCESS_BOOK_ENTRIES' = 'ACTION:PROCESS_BOOK_ENTRIES',
  'ACTION:PROCESS_LEDGER_ACCOUNT' = 'ACTION:PROCESS_LEDGER_ACCOUNT',
  'ACTION:PROCESS_JOURNAL_ENTRIES' = 'ACTION:PROCESS_JOURNAL_ENTRIES',
  'ACTION:UPDATE_PENDING_COMMISSION' = 'ACTION:UPDATE_PENDING_COMMISSION',
  'ACTION:APPROVE_COMMISSION_UPDATES' = 'ACTION:APPROVE_COMMISSION_UPDATES',
  'ACTION:DISTRIBUTE_COMMISSION' = 'ACTION:DISTRIBUTE_COMMISSION',
  'ACTION:FEE_SCHEDULE_UPDATE_REQUEST' = 'ACTION:FEE_SCHEDULE_UPDATE_REQUEST',
  'ACTION:FEE_SCHEDULE_UPDATE_APPROVAL' = 'ACTION:FEE_SCHEDULE_UPDATE_APPROVAL',
  'ACTION:FEE_COMMISSION_UPDATE_REQUEST' = 'ACTION:FEE_COMMISSION_UPDATE_REQUEST',
  'ACTION:FEE_COMMISSION_UPDATE_APPROVAL' = 'ACTION:FEE_COMMISSION_UPDATE_APPROVAL',
  'ACTION:CMS_CARD_CHANGE_EMAIL' = 'ACTION:CMS_CARD_CHANGE_EMAIL',
  'ACTION:UNIONPAY_REMITTANCE' = 'ACTION:UNIONPAY_REMITTANCE',
  'ACTION:ALIPAY_REMITTANCE' = 'ACTION:ALIPAY_REMITTANCE'
}

export interface Acl {
  [ModuleName: string]: Array<{
    permission: PermissionVerbAction;
    allow: boolean;
  }>;
}

export function isPermitted<T extends ModuleName>(
  moduleName: T,
  verbAction: PermissionVerbAction = PermissionVerbAction['ACTION:*'],
  acl?: Acl
) {
  if (!acl) {
    return false;
  }
  if (verbAction) {
    const [verb, actionName] = verbAction.toString().split(':');
    // return true if get full permission on all module
    if (!!acl['*'].find((p) => p.permission === `${verb}:*` && p.allow === true)) {
      return true;
    }
    // return true if get full permission on chosen module
    if (!!acl[moduleName].find((p) => p.permission === `${verb}:*` && p.allow === true)) {
      return true;
    }
    // return true if get module ACTION permission
    if (!!acl[moduleName].find((p) => p.permission === verbAction && p.allow === true)) {
      return true;
    }
  }
  return false;
}

export function Authorized(props: { children: any; module: ModuleName; verbAction?: PermissionVerbAction }) {
  const { initialState } = useModel('@@initialState');
  const { currentUser } = initialState || {};

  const permitted = isPermitted(props.module, props.verbAction, currentUser.acl);
  if (permitted) {
    return props.children;
  } else {
    return null;
  }
}

export default (props: { module: ModuleName; verbAction: PermissionVerbAction }) => {
  const { initialState } = useModel('@@initialState');
  const { currentUser } = initialState || {};

  const permitted = isPermitted(props.module, props.verbAction, currentUser.acl);

  if (permitted) {
    return (p: any) => p;
  } else {
    return (p: any) => (
      <div>
        <h1>Not Authorized</h1>You are not authorized to view this section.
      </div>
    );
  }
};
