import { useCallback, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';

export const useStreamEvent = (event: string, handler: (data: any, message: string) => void) => {
  const socketUrl = window['env']['ADMIN_API_URL'].replace('http', 'ws');

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    shouldReconnect: (closeEvent) => !!localStorage.getItem('token'),
    queryParams: {
      authToken: localStorage.getItem('token') as string
    },
    onMessage: (eventStr: MessageEvent) => {
      const messageEvent = JSON.parse(eventStr.data);
      if (messageEvent.event === event) {
        handler(messageEvent.data, messageEvent.event);
      }
      return;
    },
    share: true
  });

  const send = useCallback(() => sendMessage('Hello'), []);

  useEffect(() => {
    const handleStorageChange = () => {
      if (!localStorage.getItem('token')) {
        const ws = getWebSocket();
        if (ws) {
          ws.close();
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [getWebSocket]);

  return {
    send: send
  };
};
