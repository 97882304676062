import { Space, Tag } from 'antd';
import React from 'react';
import { history, SelectLang, useModel } from 'umi';
import { InstructionSelect } from '../InstructionSelect/InstructionSelect';
import { UserSelectWithLink } from '../UserSelect/UserSelectWithLink';
import Avatar from './AvatarDropdown';
import styles from './index.less';

export type SiderTheme = 'light' | 'dark';

const ENVTagColor = {
  dev: 'orange',
  test: 'green',
  pre: '#87d068',
};

const GlobalHeaderRight: React.FC<{}> = () => {
  const { initialState } = useModel('@@initialState');

  if (!initialState || !initialState.settings) {
    return null;
  }

  const { navTheme, layout } = initialState.settings;
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'top') {
    className = `${styles.right}  ${styles.dark}`;
  }

  return (
    <div className={styles.globalHeaderRight}>
      <div style={{ width: 500 }}>
        <UserSelectWithLink
          onChange={(userId) => history.push(`/users/${userId}`)}
          debounceInterval={500}
        />
      </div>
      <div style={{ width: 150, marginLeft: 20 }}>
        <InstructionSelect onChange={(data) => history.push(data)} debounceInterval={500} />
      </div>
      <Space className={className} style={{ width: 'fit-content' }}>
        <Avatar menu />
        {REACT_APP_ENV && (
          <span>
            <Tag color={ENVTagColor[REACT_APP_ENV]}>{REACT_APP_ENV}</Tag>
          </span>
        )}
        <SelectLang className={styles.action} />
      </Space>
    </div>
  );
};
export default GlobalHeaderRight;
