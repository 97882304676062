import { RequestData } from '@ant-design/pro-table';
import { request } from 'umi';
import { InstructionIntent } from './wallet';

export enum InstructionScheduleType {
  FUTURE = 'FUTURE',
  RECURRING = 'RECURRING'
}

export enum InstructionScheduleInterval {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export interface QuotePaymentDto {
  type?: string;
  attributes?: InstructionAttributeDto;
}

export interface InstructionAttributeDto {
  saveTransferPeer?: boolean;
  label?: string;
  transferPeerId?: string;
  receiverFirstName?: string;
  receiverSurname?: string;
  receiverFirstNameCN?: string;
  receiverSurnameCN?: string;
  recipient?: string;
  accountNumber?: string;
  paymentType?: string;
  paymentInfo?: string;
  bankAccount?: string;
  bankAccountProof?: string;
  billAccountNumber?: string;
  idPhoto?: string;
  name?: string;
  idNumber?: string;
  bankName?: string;
  bankAccountNumber?: string;
  receiverAccountNumber?: string;
  receiverAddress?: string;
  receiverIdType?: string;
  receiverIdNumber?: string;
  walletId?: string;
  bankCountry?: string;
  bankAddress?: string;
  bankSwiftCode?: string;
  beneficiaryName?: string;
  beneficiaryAddress?: string;
  bankCode?: string;
  merchant?: string;
}

export interface PaymentInstructionsListItem {
  id: string;
  type: string;
  user: {
    email: string;
    id: string;
    accountId?: string;
    name?: string;
    account_type?: string;
    company_name?: string;
    relationshipManager: {
      id: string;
      name: string;
    };
  };
  assetSymbol: string;
  amount: number;
  date: string;
  status: string;
  refId: string;
  method?: string;
}

export interface IntentDetailParserItem {
  type: string;
  refId: string;
}

export interface LocationItem {
  id?: string;
  name?: string;
  phone: string;
  address: string;
  latitude: number;
  timezone: string;
  longitude: number;
  responsiblePerson: string;
}

export interface BeneficiaryAttachment {
  id?: string;
  documentType?: string;
  status?: string;
  files?: Array<{ id?: string; name?: string; fileName?: string; documentKey?: string; documentUrl?: string; url?: string }>;
  details?: Array<{ id: string; name?: string; fileName?: string; documentKey?: string; documentUrl?: string }>;
}

export enum Relationship {
  spouse = 'SPOUSE',
  son = 'SON',
  daughter = 'DAUGHTER',
  parent = 'PARENT',
  sibling = 'SIBLING',
  grandson = 'GRANDSON',
  grandparent = 'GRANDPARENT',
  granddaughter = 'GRANDDAUGHTER',
  unbornChildren = 'MY_UNBORN_CHILDREN',
  unbornGrandChildren = 'MY_UNBORN_GRANDCHILDREN',
  relative = 'RELATIVE',
  friend = 'FRIEND',
  partner = 'PARTNER',
  charity = 'CHARITY',
  shareholderPersonal = 'SHAREHOLDER_PERSONAL',
  shareholderCorporate = 'SHAREHOLDER_CORPORATE',
  corporation = 'CORPORATION',
  myself = 'MYSELF',
  others = 'OTHER'
}

export interface BeneficiaryFile {
  fileList: Array<{ uid?: string; name?: string; url?: string; status?: string; response?: { url?: string } }>;
}

export interface Beneficiary {
  id: string;
  relationship: Relationship;
  distribution?: number | null;
  firstName?: string;
  lastName?: string;
  legalName?: string;
  additionalInfo?: string;
  attachments?: Array<BeneficiaryAttachment>;
  companyRegistrationNumber?: string;
  country?: string;
  dateOfBirth?: string | null;
  idPassportNumber?: string;
  mobile?: string;
  remarks?: string;
  reviewStatus: string;
  status: string;
  mobile_code?: string;
  mobile_number?: string;
  idFront?: BeneficiaryFile;
  idBack?: BeneficiaryFile;
  idPassport?: BeneficiaryFile;
  companyBr?: BeneficiaryFile;
  companyNar?: BeneficiaryFile;
  companyAtts?: BeneficiaryFile;
}

export interface InstructionRequestItem {
  accountId?: string;
  amount?: number;
  appointmentDateTimeFrom?: string;
  appointmentDateTimeTo?: string;
  createdAt: string;
  currency?: string;
  feeSnapshotId?: string;
  instructionRequestId?: string;
  location?: LocationItem;
  remarks?: string;
  transferPeerId?: string;
  beneficiaries?: Array<Beneficiary>;
}

export enum InstructionMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  CASH_DROP_OFF = 'CASH_DROP_OFF',
  CASH_PICKUP = 'CASH_PICKUP',
  UNIONPAY = 'UNIONPAY',
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_SWIFT = 'BANK_SWIFT',
  ALIPAY = 'ALIPAY'
}

export function intentParser(refId: string) {
  const intent = refId ? refId.substring(0, 2) : null;
  switch (intent) {
    case 'GI':
      return 'GENERAL_INSTRUCTION';
    case 'FI':
      return 'FEE';
    case 'CT':
      return 'CREDIT_TRANSACTION';
    default:
      return null;
  }
}

export function intentDetailUrlParser(record: IntentDetailParserItem) {
  const intent = intentParser(record.refId) ?? record.type;
  switch (intent) {
    case InstructionIntent.DEPOSIT_DIGITAL:
    case 'DIGITAL_DEPOSIT':
      return '/instructions/digital-deposits';
    case 'FIAT_WITHDRAW':
    case InstructionIntent.WITHDRAW_FIAT:
      return '/instructions/fiat-withdraws';
    case InstructionIntent.WITHDRAW_DIGITAL:
    case 'DIGITAL_WITHDRAW':
      return '/instructions/digital-withdraws';
    case InstructionIntent.DEPOSIT_FIAT:
    case 'FIAT_DEPOSIT':
      return '/instructions/fiat-deposits';
    case InstructionIntent.COMMODITIES:
    case InstructionIntent.COMMODITIES_DEPOSIT:
    case InstructionIntent.COMMODITIES_WITHDRAW:
    case InstructionIntent.TRADER_MINT:
    case InstructionIntent.TRADER_BURN:
    case InstructionIntent.REPAY:
    case 'BORROW':
    case 'CREDIT_TRANSACTION':
      return '/cms/transactions';
    case InstructionIntent.EXCHANGE:
      return '/instructions/exchanges';
    case InstructionIntent.BILL_PAYMENT:
      return '/instructions/bill-payments';
    case InstructionIntent.TRANSFER_SELF:
    case InstructionIntent.TRANSFER_P2P:
    case 'WALLET_TRANSFER':
      return '/instructions/transfers';
    case 'GENERAL_INSTRUCTION':
    case InstructionIntent.FOREX:
    case InstructionIntent.SECURITIES_TRANSFER:
    case InstructionIntent.INSURANCE:
    case InstructionIntent.ASSET_INJECTION:
    case InstructionIntent.ADJUSTMENT:
    case InstructionIntent.CARD_PAYMENT:
    case InstructionIntent.COMMODITIES:
    case InstructionIntent.CHARGE:
    case InstructionIntent.TRANSFER:
    case InstructionIntent.FUND_SUBSCRIPTION:
    case InstructionIntent.BOND_SUBSCRIPTION:
    case InstructionIntent.SECURITIES_APPLICATION:
    case InstructionIntent.EXCHANGE_SUB_ACCOUNT_APPLICATION:
    case InstructionIntent.EXCHANGE_SUB_ACCOUNT_TRANSFER:
    case InstructionIntent.EXCHANGE_SUB_ACCOUNT_MONTHLY_FEE:
    case InstructionIntent.CHANGE_BENEFICIARIES:
    case InstructionIntent.INSURANCE_ENQUIRY:
    case InstructionIntent.SETTLEMENT:
    case InstructionIntent.FUND_DISTRIBUTION:
    case InstructionIntent.COMMISSION:
    case InstructionIntent.ASSET_WITHDRAWAL:
      return '/instructions/general-instruction';
    case InstructionIntent.FEE_PAYOUT:
    case 'FEE':
      return '/instructions/fee-payouts';
    default:
      return null;
  }
}

export async function queryPendingPaymentInstructions(params: any): Promise<RequestData<PaymentInstructionsListItem>> {
  return await request<RequestData<PaymentInstructionsListItem>>('/instructions/pending', {
    params: params
  });
}
