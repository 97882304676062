import {RequestData} from "@ant-design/pro-table";
import {request} from "@@/plugin-request/request";

export interface CardPendingEmbossedListItem {
  cardId: string,
  cardNumber: string,
  cardProduct: string,
  cardEmbossedName: string,
  accountId: string,
  relationshipManager: string,
  cardIssuedAt: Date,
  userId: string,
  fulfillmentNote: string;
  cardProductId: string;
  clientName: string;
}



export async function getCardPendingEmbossedList(params: any): Promise<RequestData<CardPendingEmbossedListItem>> {
  return await request<RequestData<CardPendingEmbossedListItem>>('/cms/cards/pending-embossed', { params });
}
