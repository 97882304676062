import { RequestData } from '@ant-design/pro-table';
import { request } from 'umi';

export interface DigitalDepositListItem {
  id: string;
  status: string;
  amount: string;
  date: Date;
  refId: string;
  network: string;
  fromAddress: string;
  fee: number;
  publicTransactionHash: string;
  receiptPhotoUrl?: string;
  wallet: {
    id: string;
    label: string;
    name: string;
  };
  user: {
    id: string;
    name: string;
    email: string;
    accountId: string;
  };
  assetSymbol: string;
  description: string;
}

export interface DigitalDepositDetail extends DigitalDepositListItem {}

export enum DigitalDepositPendingStatus {
  PENDING = 'PENDING',
  SKIPPED = 'SKIPPED',
  PROCESSED = 'PROCESSED'
}

export interface DigitalDepositPendingListItem extends DigitalDepositListItem {
  transactionHash: string;
  destinationAddress: string;
  status: DigitalDepositPendingStatus;
  pendingReason: string;
  depositId: string;
}

export async function queryDigitalDeposit(params: any): Promise<RequestData<DigitalDepositListItem>> {
  if (params.date) {
    params.from = params.date[0];
    params.to = params.date[1];
    delete params.date;
  }
  if (params['user,email']) {
    params.email = params['user,email'];
    delete params['user,email'];
  }
  return await request<RequestData<DigitalDepositListItem>>('/digital-deposits', {
    params: params
  });
}

export async function queryDigitalDepositDetail(params: { id: string }) {
  const data = await request<DigitalDepositDetail>(`/digital-deposits/${params.id}`);
  return {
    data
  };
}

export async function reconcileUnallocatedDeposits(
  requestedDeposits: DigitalDepositListItem[],
  unallocatedDeposit: DigitalDepositListItem,
  yotpToken: string,
  remarks: string
) {
  const data = await request(`/digital-deposits/reconcile`, {
    method: 'POST',
    data: {
      digital_deposit_id: unallocatedDeposit.id,
      user_submitted_request_ids: requestedDeposits.map((d) => d.id),
      _yotpToken: yotpToken,
      remarks
    }
  });

  return {
    data
  };
}

export async function editDigitalDepositsDetail(params: { id: string; description: string }) {
  const postData = await request(`/digital-deposits/${params.id}/edit`, {
    method: 'POST',
    data: {
      ...params,
      method: 'post'
    }
  });
  return {
    postData
  };
}

export async function queryDigitalDepositPending(params: Record<string, any>): Promise<RequestData<DigitalDepositPendingListItem>> {
  if (params.date) {
    params.fromDate = params.date[0];
    params.toDate = params.date[1];
    delete params.date;
  }

  return await request<RequestData<DigitalDepositPendingListItem>>('/digital-deposits/digital-deposits-pending/list', {
    method: 'GET',
    params: params
  });
}
