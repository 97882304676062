import { RequestData } from '@ant-design/pro-table';
import { lowerCase } from 'lodash';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import { request } from 'umi';
import { FilingFolder } from './client-file-system';

export const getClientDocumentType = async () => {
  let documentType = [];
  await request<{ data: any[] }>(`/client-document/document-categories/document-type`, {
    getResponse: true
  }).then(({ data }) => {
    data.forEach((element) => {
      documentType[element] = element;
    });
    return documentType;
  });
  return documentType;
};

export enum ClientDocumentStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  REMOVED = 'REMOVED',
  PENDING = 'PENDING'
}

export interface DocumentsListItem {
  id: string;
  name: string;
  status: ClientDocumentStatus;
  documentType: string;
  documentCategories: string[];
  createdAt: Date;
  expiryDate: Date;
  isExpired: boolean;
  nextReviewDate: Date;
  clientVisible: boolean;
  operator: string;
  userId: string;
  accountId: string;
  userName: string;
  userAccountNumber: string;
  userEmail: string;
  userKycLevel: string;
  description?: string;
  details: DocumentsListFile;
  metadata?: ClientDocumentMetadata;
  filingFolder?: FilingFolder;
  uploadDate: Date;
}

export interface ClientDocumentRequestParams {
  id: string;
  name: string;
  status: ClientDocumentStatus | ClientDocumentStatus[];
  documentType: string | string[];
  clientVisible: boolean;
  userId: string;
  metadata?: ClientDocumentMetadata;
}

export interface ClientDocumentDetailDto {
  id: string;
  uploadAt: string;
  documentId?: string;
  key?: string;
  name: string;
  thumbnailUrl: string;
  fileName: string;
  extension: string;
  documentKey: string;
  documentUrl: string;
  order?: number;
}

export interface DocumentsListFile {
  [key: string]: ClientDocumentDetailDto;
}

export const defaultClientDocumentFormValues: ClientDocumentFormValues = {
  id: '',
  name: '',
  documentType: '',
  status: ClientDocumentStatus.NEW,
  uploadDate: null,
  expiryDate: null,
  nextReviewDate: null,
  userId: '',
  clientVisible: false,
  operator: '',
  createdAt: '',
  updatedAt: '',
  details: {},
  metadata: {},
  description: ''
};

export interface ClientDocumentMetadata {
  securitiesStatementType?: 'DAILY' | 'MONTHLY';
  securitiesStatementDate?: Moment | null;
  securitiesAccountNumber?: string;
  securitiesAccountCurrency?: string;
  securitiesBrokerId?: string;
  securitiesAccountId?: string;
  alternativesId?: string;
  insurancePolicyId?: string;
  generalInstructionId?: string;
  billPaymentId?: string;
  exchangeSubAccountStatementType?: 'DAILY' | 'MONTHLY';
  exchangeSubAccountStatementDate?: Moment | null;
  exchangeSubAccountAccountNumber?: string;
  exchangeSubAccountCurrency?: string;
  exchangeSubAccountBrokerId?: string;
  exchangeSubAccountId?: string;
  transferPeerId?: string;
}
export interface ClientDocumentFormValues {
  id: string;
  name: string;
  documentType: string;
  status: ClientDocumentStatus;
  uploadDate: Moment | null;
  expiryDate: Moment | null;
  nextReviewDate: Moment | null;
  userId: string | null;
  clientVisible: boolean;
  operator: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  details: {
    [key: string]: ClientDocumentDetailDto;
  };
  removeDocumentIds?: string[];
  metadata?: ClientDocumentMetadata;
  remark?: string;
  remarkChannel?: string;
  _yotpToken?: string;
}

export interface UpdateClientDocumentStatusParams {
  status: ClientDocumentStatus;
  ids: string[] | undefined;
  remark?: string;
  remarkChannel?: string;
  _yotpToken?: string;
}

export interface CreateUpdateClientDocumentParams {
  id?: string;
  userId?: string;
  expiryDate?: Date;
  nextReviewDate?: Date;
  name?: string;
  documentType: string;
  status: ClientDocumentStatus;
  clientVisible: boolean;
  description: string;
  files: ClientDocumentDisplayFile[];
  remark?: string;
  remarkChannel?: string;
  metadata?: ClientDocumentMetadata;
  _yotpToken?: string;
}

export interface ClientDocumentDisplayFile {
  clientDocumentId?: string;
  id: string;
  displayName: string;
  fileName: string;
  url?: string;
  thumbnailUrl?: string;
  isDeleted: boolean;
  extension: string;
  documentUrl: string;
  uploadAt?: string;
  isUploading?: boolean;
  order: number;
}

const url = '/client-document';

export async function getClientDocuments(params: any): Promise<RequestData<DocumentsListItem>> {
  if (params.expiryDate) {
    params.expiryDateFrom = params.expiryDate[0];
    params.expiryDateTo = params.expiryDate[1];
    delete params.expiryDate;
  }

  if (params.isExpired === 'true') {
    params.expiryDateFrom =
      params.expiryDateFrom && moment(params.expiryDateFrom).isBefore(moment().subtract(1, 'day')) ? params.expiryDateFrom : null;
    params.expiryDateTo =
      params.expiryDateTo && moment(params.expiryDateTo).isBefore(moment().subtract(1, 'day'))
        ? params.expiryDateTo
        : moment().subtract(1, 'day').format('YYYY-MM-DD');
    delete params.isExpired;
  } else if (params.isExpired === 'false') {
    params.expiryDateTo = params.expiryDateTo && moment(params.expiryDateTo).isAfter(moment()) ? params.expiryDateTo : null;
    params.expiryDateFrom =
      params.expiryDateFrom && moment(params.expiryDateFrom).isAfter(moment()) ? params.expiryDateFrom : moment().format('YYYY-MM-DD');
    delete params.isExpired;
  }

  return await request<RequestData<DocumentsListItem>>(url, { params });
}

export async function getClientDocument(id: string) {
  return await request<DocumentsListItem>(`${url}/${id}`);
}

export async function createClientDocument(data: CreateUpdateClientDocumentParams) {
  return await request<{ id: string }>(url, {
    method: 'post',
    data,
    getResponse: true
  });
}

export async function updateClientDocument(data: CreateUpdateClientDocumentParams) {
  return await request<{ id: string }>(`${url}/${data.id}/update`, {
    method: 'post',
    data,
    getResponse: true
  });
}

export async function updateClientDocuments(data: UpdateClientDocumentStatusParams) {
  return await request<{ id: string }>(`${url}/batch-update-status`, {
    method: 'post',
    data,
    getResponse: true
  });
}

export async function getDownloadDocument(id: string, fileId: string) {
  return await request(`${url}/${id}/download/?fileId=${fileId}`, {
    method: 'post',
    data: { id, fileId }
  });
}

export async function downloadDocument(id: any, fileId: string) {
  const ret = await getDownloadDocument(id, fileId);
  if (ret) {
    window.open(ret, '_blank');
  }
}

export function getStatusColor(status: string) {
  switch (status) {
    case ClientDocumentStatus.NEW:
      return 'blue';
    case ClientDocumentStatus.CANCELLED:
      return 'orange';
    case ClientDocumentStatus.APPROVED:
      return 'green';
    case ClientDocumentStatus.REJECTED:
      return 'red';
    case ClientDocumentStatus.PENDING:
      return 'lime';
    default:
      return 'gray';
  }
}

export function getColumnName(type: string) {
  return (
    lowerCase(type.substring(type.indexOf('_') + 1))
      .charAt(0)
      .toUpperCase() + lowerCase(type.substring(type.indexOf('_') + 1)).slice(1)
  );
}

export async function getDownloadDocuments(downloadList: { [key: string]: String[] }) {
  return await request(`${url}/batch-download/`, {
    method: 'post',
    data: downloadList
  });
}
