import { RequestData } from '@ant-design/pro-table';
import { request } from 'umi';
import { ClientFootprint } from './user';
import { Application } from './card-application';

export interface SolutionApplicationItem {
  id: string;
  user: {
    name: string;
    email: string;
    accountId: string;
    id: string;
    relationshipManager?: string;
    vendorName: string;
  };
  rm: string;
  solutionName: string;
  status: string;
  lastUpdated: string;
  updatedAt?: Date;
  startedAt?: Date;
  operatorName?: string;
  clientFootprint: ClientFootprint;
  name?: string;
  cardApplication?: Application;
}

export enum SolutionType {
  UTGL_ACCOUNT_UPGRADE = 'UTGL_ACCOUNT_UPGRADE',
  UTGL_VIRTUAL_CARD_APPLICATION = 'UTGL_VIRTUAL_CARD_APPLICATION',
  UTGL_ONBOARDING = 'UTGL_ONBOARDING',
  UZPAY_ONBOARDING = 'UZPAY_ONBOARDING',
  FXP_ONBORADING = 'FXP_ONBORADING'
}

export interface SolutionItem {
  id: string;
  code: string;
  name: string;
  type: SolutionType;
  available: boolean;
}

export async function querySolutionApplications(params: any): Promise<RequestData<SolutionApplicationItem>> {
  if (params.user && params.user.id) {
    params.userId = params.user.id;
  }

  return request<RequestData<SolutionApplicationItem>>('/solutions', { params });
}

export async function querySolutionList(params: any): Promise<RequestData<SolutionItem>> {
  if (params.user && params.user.id) {
    params.userId = params.user.id;
  }

  return request<RequestData<SolutionItem>>('/solutions/list', { params });
}
