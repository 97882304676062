// @ts-nocheck

import VerticalAlignTopOutlined from '@ant-design/icons/VerticalAlignTopOutlined';
import CreditCardOutlined from '@ant-design/icons/CreditCardOutlined';
import MoneyCollectOutlined from '@ant-design/icons/MoneyCollectOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined'

export default {
  VerticalAlignTopOutlined,
CreditCardOutlined,
MoneyCollectOutlined,
UserOutlined
}
    