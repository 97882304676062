import { DirectorShareholderType, RiskLevelType } from '@/pages/users/components/UserAccountForm';
import { PrimaryContactMethodType } from '@/pages/users/components/UserContact';
import { RequestData } from '@ant-design/pro-table';
import { Moment } from 'moment-timezone';
import { request } from 'umi';
import { ClientDocumentStatus } from './client-documents';
import { UserGroupItem } from './fee-setting-management';
import { Application } from './card-application';

export enum UserStatus {
  'ACTIVE' = 'ACTIVE',
  'SUSPENDED' = 'SUSPENDED',
  'CLOSED' = 'CLOSED'
}

export enum ExpectedVolumeThresholdPeriodType {
  ONE_WEEK = 'One Week',
  ONE_MONTH = 'One Month',
  THREE_MONTH = 'Three Months'
}

export interface User {
  referralCode: string;
  id: string;
  accountNumber?: string;
  accountId: string;
  accountTier: { id: number; tier: string };
  accountTierLabel: string;
  email: string;
  emailVerified: true;
  kycDocumentsStatus: {
    addressProof: string | null;
    idFront: string | null;
    idBack: string | null;
    selfie: string | null;
    passport: string | null;
  };
  kycLevel: {
    auto_approve: string;
    created_at: Date;
    description: { 'en-US': string; 'zh-HK': string; 'zh-CN': string };
    id: number;
    kyc_level: { 'en-US': string; 'zh-HK': string; 'zh-CN': string };
    kyc_required_fields: object[];
    kyc_user_required_fields: object[];
    next_level_id: number;
    updated_at: Date;
  };
  name: string;
  firstName: string;
  lastName: string;
  contactPerson: string;
  contactEmail: string;
  contactCountryCode: string;
  contactMobile: string;
  contactAddress: string;
  companyName: string;
  countryCode: string;
  accountType: 'PERSONAL' | 'CORPORATE' | 'COBRAND';
  phone: string;
  phoneVerified: boolean;
  signupCode: string;
  signupDate: Date;
  riskLevel: string;
  nationality: string;
  idNumber: string;
  idCountry: string;
  passportNumber: string;
  dateOfBirth: Date;
  gender: string;
  occupation: string;
  vendor: {
    id: number;
    name: string;
    website: string;
  };
  relationshipManager: {
    id: string;
    name: string;
    telegramUserId: string;
  };
  metadata: {
    _hkmsosId?: string;
    _welcomeEmailSentAt?: Date;
    securitiesAccount?: { enabled?: boolean };
    fundAccount?: { enabled?: boolean };
    bondAccount?: { enabled?: boolean };
    fundAndBondAccount?: { enabled?: boolean };
    exchangeSubAccount?: { enabled?: boolean };
    statementOptions?: {
      statementName?: string;
      statementAddress?: string;
    };
  };
  status: UserStatus;
  newPasswordRequired: boolean;
  trustName: string;
  settlorName: string;
  serviceType: string;
  trustFullName: string;
  trustAccountName: string;
  lastLoginTime: Date;
  secureEmailNotification: boolean;
  expectedVolumeThreshold: ExpectedVolumeThreshold[];
  tuRatings: {
    tuLabel: string;
    override: number;
  };
  spe: string;
  clientSummary: string;
  groupIds: Array<string>;
  userGroups: Array<UserGroupItem>;
  crsReporting: boolean;
  usRelated: boolean;
  watchlist: boolean;
  cardProgramOptOut?: boolean;
  marketingOptOut?: boolean;
  taxCountry: string;
  tinNumber: string;
  trustSetupDate: Date;
  directorShareholderInfos: DirectorShareholderInfo[];
  preferLanguage: string;
  parentAccountId: string;
  closureReason?: string;
  closureDate?: Date;

  residentialAddress?: string;
  placeOfBusiness?: string;
  countryOfIncorporation?: string;
  dateOfIncorporation?: Date | Moment;
  notionPageId?: string;
  f2fVerified?: boolean;
  f2fVerifiedAt?: Date;
  anniversaryDate?: Date;
  nameCheckResult?: string[];
  industry?: string;
  annualIncome?: string;
  employmentStatus?: string;
  sourceOfWealth?: string;
  solutionName?: string;
  lastOnlineAt?: string;
  localFirstName?: string;
  localLastName?: string;
  poaStatus?: string;
  passportExpiryDate?: Date;
  documentIssueDate?: Date;
}

export interface PaymentKYCInstructionsListItem {
  submittedAt: string;
  id: string;
  email: string;
  pendingDocumentCount: number;
  relationshipManager?: {
    id: string;
    name: string;
  };
  _hkmsosId?: string;
  status: {
    [key: string]: ClientDocumentStatus;
  };
  user: {
    id: string;
    accountNumber: string;
    name: string;
    email: string;
    accountType: string;
    companyName: string;
  };
}

export interface UsersBeneficiariesListItem {
  submittedAt: string;
  id: string;
  email: string;
  relationshipManager?: {
    id: string;
    name: string;
  };
  accountType: string;
  companyName: string;
  _hkmsosId?: string;
  user: {
    id: string;
    accountNumber: string;
    name: string;
  };
  status: string;
  reviewStatus: string;
  relationship: string;
  name: string;
  solution: string;
}

export enum UserBeneficiariesV2Status {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REMOVED = 'REMOVED'
}

export enum UserBeneficiariesV2ReviewStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  MISSING_INFO = 'MISSING_INFO'
}

export enum RelationShip {
  SPOUSE = 'SPOUSE',
  SON = 'SON',
  DAUGHTER = 'DAUGHTER',
  PARENT = 'PARENT',
  GRANDPARENT = 'GRANDPARENT',
  SIBLING = 'SIBLING',
  GRANDSON = 'GRANDSON',
  GRANDDAUGHTER = 'GRANDDAUGHTER',
  FRIEND = 'FRIEND',
  PARTNER = 'PARTNER',
  RELATIVE = 'RELATIVE',
  CHARITY = 'CHARITY',
  SHAREHOLDER_PERSONAL = 'SHAREHOLDER_PERSONAL',
  SHAREHOLDER_CORPORATE = 'SHAREHOLDER_CORPORATE',
  CORPORATION = 'CORPORATION',
  MYSELF = 'MYSELF',
  MY_UNBORN_CHILDREN = 'MY_UNBORN_CHILDREN',
  MY_UNBORN_GRANDCHILDREN = 'MY_UNBORN_GRANDCHILDREN',
  OTHER = 'OTHER'
}

export interface UsersCraFormListItem {
  id: string;
  riskLevel: string;
  preparedBy: string;
  date: string;
  user: {
    id: string;
    accountNumber: string;
    name: string;
    email: string;
    accountType: string;
    companyName: string;
  };
  relationshipManager?: {
    id: string;
    name: string;
  };
}

export interface UserDigitalAddressListItem {
  createdAt: string;
  chainType: string;
  id: string;
  walletAddress: string;
  userId: string;
  remarks: string;
  remarkChannel: string;
}

export interface UsersRemarks {
  id: string;
  name: string;
  email: string;
  objectType: string;
  userRemarks: string;
  createdAt: Date;
}

export interface UserRemarkListItem {
  id: string;
  object: {
    type: string;
    id: string;
    action: string;
  };
  remark: string;
  remarkChannel: string;
  time: Date;
  operator: {
    name: string;
  };
}
export interface UserBindingListItem {
  id: string;
  status: string;
  email: string;
  userName: string;
  emailVerified: boolean;
  mobileVerified: boolean;
  newPasswordRequired: string;
  countryCode: number;
  mobile: string;
  tfaEnabled: boolean;
  gaTfaEnabled: boolean;
  defaultUserId: string;
  vendor: {
    id: number;
    name: string;
  };
  accountDetails: Array<{
    accountNumber: string;
    userId: string;
  }>;
  lastLoginTime: string | null;
  locked: boolean;
}

interface UserFeaturesDTO {
  kycLevelId: number;
  name: string;
  features: {
    feature: string;
    enabled: boolean;
  }[];
}

export interface GetLoginAccountsParams {
  status: string;
  id: string;
  email: string;
  fromDate: Date;
  toDate: Date;
  isLoginSelect: boolean;
  userId: string;
}

export interface UserLoginHistoryListItem {
  acceptLanguage: string;
  createdAt: string;
  expiry: string;
  id: string;
  ipAddress: string;
  isSuccess: boolean;
  method: string;
  updatedAt: string;
  userAgent: string;
  userId: string;
  loginEmail: string;
}

export interface ExpectedVolumeThreshold {
  period: ExpectedVolumeThresholdPeriodType;
  amount: number;
  currency: string;
}

export interface DirectorShareholderInfo {
  name: string;
  chineseName: string;
  dateOfBirth: Moment;
  nationality: string;
  passportOrIdentityNumber: string;
  issueingCountryOrRegion: string;
  passportExpiryDate: Moment;
  email: string;
  address: string;
  sharePercentage: number;
  allocationRatio: number;
  workPhone: string;
  mobilePhone: string;
  taxResidency: string;
  tinNo: string;
  countryRisk: RiskLevelType;
  remark: string;
  remarkChannel: string;
  accountType: DirectorShareholderType;
}

export interface UserContact {
  id: string;
  phone: string;
  phoneCountryCode: string;
  phoneVerified: boolean;
  email: string;
  emailVerified: boolean;
  telegramId: string;
  wechatId: string;
  whatsapp: string;
  lineId: string;
  primaryContactMethod: PrimaryContactMethodType;
  linkedinId: string;
  facebookId: string;
  instagramId: string;
  userHavingMultipleLogin: boolean;
  notionPageId: string;
}

export enum BeneficiaryStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  NEW = 'NEW',
  REMOVED = 'REMOVED'
}

export interface UserBeneficiaryListItem {
  id: string;
  user_id?: User;
  name: string;
  surname: string;
  given_name: string;
  name_in_chinese: string;
  dob: Date;
  gender: string;
  restendal_address: string;
  tax_residency: string;
  nationality: string;
  identification: string;
  pep: boolean;
  pep_details: string;
  us_tax_payer: boolean;
  us_tax_payer_details: string;
  tin: string;
  tin_details: string;
  relationship: string;
  contact_number: string;
  occupation: string;
  company_detail: string;
  allocation: number;
  status: BeneficiaryStatus;
  removed_at: Date;
  created_at: Date;
  updated_at: Date;
}

export enum ClientFootprint {
  VERIFICATION = 'VERIFICATION',
  DEPOSIT = 'DEPOSIT',
  REFERENCE = 'REFERENCE',
  REFERCODE = 'REFERCODE',
  CARD_APPLCATION = 'CARD_APPLCATION',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  COMPLETED = 'COMPLETED',
  NONE = 'NONE'
}

export interface UserSolutionListItem {
  id: string;
  solutionId: string;
  userId: string;
  name: string;
  clientFootprint: ClientFootprint;
  status: string;
  startedAt: Date;
  updatedAt: Date;
  default?: Boolean;
  solutionType?: string;
  solutionCode?: string;
  operatorName?: string;
  cardApplication?: Application;
}

export interface UserBeneficiaryResponse {
  trustName: string;
  settlorName: string;
  trustFullName: string;
  trustAccountName: string;
  userBeneficiaries: UserBeneficiaryListItem[];
}

export async function queryUserById(params: { id: string }) {
  const data = await request<User>(`/users/${params.id}`, { params });
  return {
    data
  };
}

export async function queryUserDigitalAddress(params: any): Promise<RequestData<UserDigitalAddressListItem>> {
  const data = await request<RequestData<UserDigitalAddressListItem>>(`/user-digital-addresses/${params.userId}`, { params });
  return data;
}

export async function queryCurrent() {
  return request<any>('/~/me');
}

export async function queryMyRoutes() {
  return request<any>('/~/routes');
}

export async function getKycDocuments(userId: string) {
  return request<any>(`/users/${userId}/kyc-documents`, {
    getResponse: true
  });
}

export async function approveKyc(data: { userId: string; remark: string; remarkChannel: string }) {
  return request<any>(`/users/${data.userId}/kyc/approve`, {
    method: 'post',
    data: {
      remark: data.remark,
      remarkChannel: data.remarkChannel
    }
  });
}

export async function rejectKyc(data: { userId: string; remark: string; remarkChannel: string }) {
  return request<any>(`/users/${data.userId}/kyc/reject`, {
    method: 'post',
    data: {
      remark: data.remark,
      remarkChannel: data.remarkChannel
    }
  });
}

export async function approveKycDocuments(data: { documents: string[]; userId: string; remark: string; remarkChannel: string }) {
  return request<any>(`/users/${data.userId}/kyc/approve-document`, {
    method: 'post',
    data: {
      documents: data.documents,
      remark: data.remark,
      remarkChannel: data.remarkChannel
    }
  });
}

export async function rejectKycDocuments(data: { documents: string[]; userId: string; remark: string; remarkChannel: string }) {
  return request<any>(`/users/${data.userId}/kyc/reject-document`, {
    method: 'post',
    data: {
      documents: data.documents,
      remark: data.remark,
      remarkChannel: data.remarkChannel
    }
  });
}

export async function queryPendingKYCInstructions(params: any): Promise<RequestData<PaymentKYCInstructionsListItem>> {
  return await request<RequestData<PaymentKYCInstructionsListItem>>('/users/kyc/pending', {
    params: params
  });
}

export async function queryUsersBeneficiaries(params: any): Promise<RequestData<UsersBeneficiariesListItem>> {
  return await request<RequestData<UsersBeneficiariesListItem>>('/users/beneficiaries/pending', {
    params: params
  });
}

export async function queryUsersCraForm(params: any): Promise<RequestData<UsersCraFormListItem>> {
  return await request<RequestData<UsersCraFormListItem>>('/users/cra-form/pending', {
    params: params
  });
}

export async function uploadKycDocuments(data: { documents: object[]; userId: string; remark: string; remarkChannel: string }) {
  return await request<any>(`/users/${data.userId}/kyc-documents`, {
    method: 'post',
    data: {
      documents: data.documents,
      remark: data.remark,
      remarkChannel: data.remarkChannel
    }
  });
}

export async function sendEmail(data: { userId: string; subject: string; content: string; _yotpToken?: string; loginId?: string }) {
  return request<any>(`/users/${data.userId}/send-email`, {
    method: 'post',
    data: {
      loginId: data.loginId,
      subject: data.subject,
      content: data.content,
      _yotpToken: data._yotpToken
    }
  });
}

export async function queryUserRemarks(params: any): Promise<RequestData<UserRemarkListItem>> {
  const data = await request<RequestData<UserRemarkListItem>>(`/remarks`, { params });
  return data;
}

export async function queryUserLoginHistory(userId: string): Promise<RequestData<UserLoginHistoryListItem>> {
  return await request<RequestData<UserLoginHistoryListItem>>(`/user/${userId}/get-history`);
}

export async function queryUserContactById(userId: string) {
  const data = await request<UserContact>(`/users/${userId}/contact-info`);
  return {
    data
  };
}

export async function queryUserBinding(params: any): Promise<RequestData<UserBindingListItem>> {
  return await request<RequestData<UserBindingListItem>>(`/logins/account-associated`, { params });
}

export async function updateUserInfo(data: {
  userId: string;
  vendorId: number;
  phone: string;
  email: string;
  telegramId: string;
  wechat: string;
  whatsapp: string;
  lineId: string;
  primaryContactMethod: PrimaryContactMethodType;
  remark: string;
  remarkChannel: string;
  _yotpToken: string;
}) {
  return request<any>(`/users/${data.userId}/contact-info`, {
    method: 'post',
    data: {
      userId: data.userId,
      vendorId: data.vendorId,
      phone: data.phone,
      email: data.email,
      telegramId: data.telegramId,
      wechat: data.wechat,
      whatsapp: data.whatsapp,
      lineId: data.lineId,
      PrimaryContactMethodType: data.primaryContactMethod,
      _yotpToken: data._yotpToken
    }
  });
}
export async function bindAccountToLogin(data: {
  loginId: string;
  userId: string;
  vendorId: number;
  _yotpToken: string;
  email: string;
  emailVerified: boolean;
  mobile: string;
  countryCode: string;
  mobileVerified: boolean;
  isNewLogin: boolean;
  password: string;
}) {
  return await request<any>(`/logins/bind-account-to-login`, {
    method: 'post',
    data: {
      _yotpToken: data._yotpToken,
      loginId: data.loginId,
      userId: data.userId,
      vendorId: data.vendorId,
      email: data.email,
      emailVerified: data.emailVerified,
      mobile: data.mobile,
      countryCode: data.countryCode,
      mobileVerified: data.mobileVerified,
      isNewLogin: data.isNewLogin,
      password: data.password
    }
  });
}

export async function getUserNav(params: { userId: string }) {
  const data = await request<number>(`/users/${params.userId}/nav`);
  return {
    data
  };
}

export async function deleteLoginBinding(data: { loginId: string; userId: string; remarks: any; remarkChannel: any; _yotpToken: any }) {
  return await request<any>(`/logins/delete-login-binding`, {
    method: 'delete',
    data: {
      _yotpToken: data._yotpToken,
      loginId: data.loginId,
      userId: data.userId,
      remarks: data.remarks,
      remarkChannel: data.remarkChannel
    }
  });
}

export async function setAccountDefault(data: { loginId: string; recordId: string }) {
  return await request(`/logins/${data.loginId}/set-default-account/${data.recordId}`, {
    method: 'post',
    getResponse: true
  });
}

export async function registerNewLogin(data: {
  vendorId: number;
  _yotpToken: string;
  email?: string;
  emailVerified?: boolean;
  mobile?: string;
  countryCode?: string;
  mobileVerified?: boolean;
  password?: string;
}) {
  return await request<any>(`/logins`, {
    method: 'post',
    data: {
      vendorId: data.vendorId,
      email: data?.email,
      emailVerified: data?.emailVerified,
      mobile: data?.mobile,
      mobileVerified: data?.mobileVerified,
      password: data?.password,
      countryCode: data?.countryCode,
      _yotpToken: data._yotpToken
    }
  });
}

export async function updateFeatures(data: { userId: string; features: any; _yotpToken: any }) {
  return await request<any>(`/user-features`, {
    method: 'Post',
    data: {
      userId: data.userId,
      features: data.features,
      _yotpToken: data._yotpToken
    }
  });
}

export async function queryUserSolution(params: any): Promise<RequestData<UserSolutionListItem>> {
  return await request<RequestData<UserSolutionListItem>>(`/solutions`, { params });
}
