import { RequestData } from '@ant-design/pro-table';
import { request } from 'umi';

export enum ScheduleInterval {
  TWO_MINUTES = 'TWO_MINUTES',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY'
}

export interface ScheduledInstruction {
  id: string;
  date: string;
  userId: string;
  accountNumber: string;
  email: string;
  intent: string;
  intentId: string;
  refId: string;
  nextRunAt: string;
  interval: ScheduleInterval;
  numberOfRuns: number;
  totalNumberOfInstruction: number;
  endDate: string;
  active: boolean;
  userName: string;
  amount: number;
  feeAmount: number;
  currency: string;
}

export async function getScheduledGeneralInstructions(params: any): Promise<RequestData<ScheduledInstruction>> {
  const data = await request<RequestData<ScheduledInstruction>>('/scheduler/general-instruction', {
    params
  });
  return data;
}

export async function cancelActiveScheduledInstruction(params: { id: string; remark: string; remarkChannel: string; _yotpToken: string }) {
  return await request(`/scheduler/general-instruction/${params.id}/cancel`, {
    method: 'POST',
    data: params
  });
}
