import { FeePreview } from '@/pages/cost-and-fees/fee-previewer/types';
import { InstructionOverrideProps } from '@/pages/general-instruction/components/Form';
import { RequestData } from '@ant-design/pro-table';
import { request } from 'umi';
import { ClientDocumentDetailDto, ClientDocumentDisplayFile } from './client-documents';
import { InstructionStatus } from './wallet';

export enum PaymentMethod {
  CASHIER_CHEQUE = 'Cashier Cheque',
  ISSUE_CHEQUE = 'Issue Cheque',
  CASH = 'Cash',
  PPS = 'PPS',
  BANK_TRANSFER_FPS = 'Bank Transfer (FPS)',
  BANK_TRANSFER_SWIFT = 'Bank Transfer (SWIFT)',
  BANK_TRANSFER_CHATS = 'Bank Transfer (CHATS)',
  PAYMENT_AGENT = 'Payment Agent',
  DIGITAL = 'Digital',
  RELATED_PARTY = 'Related Party',
  OTHERS = 'Others',
  UTGL = 'UTGL'
}

export enum PaymentDetailType {
  SOURCE = 'SOURCE',
  DESTINATION = 'DESTINATION'
}

export type PaymentDetail = {
  id?: string;
  isDeleted?: boolean;
  type: PaymentDetailType;
  method: PaymentMethod;
  data: { [property: string]: string | string[] };
};
export interface BillPaymentsListItem {
  id: string;
  refId: string;
  country: string;
  date: string;
  type: string;
  merchant: string;
  amount: string;
  fee: number;
  currency: string;
  status: string;
  instructionStatus: InstructionStatus;
  userRemarks: string;
  requestedAt: Date;
  processingAt: Date;
  processedAt: Date;
  billAccountNumber: string;
  paymentAdditionalDetails: string;
  user: {
    id: string;
    name: string;
    email: string;
    account: string;
  };
  rm: { id: string; name: string };
  operator: {
    id: string;
    name: string;
    email: string;
  };
  description: string;
  chequeInfo: {
    accountNumber?: string;
    payee?: string;
    amount?: string;
    currency?: string;
    date?: Date;
    remarks?: string;
    remarkChannel?: string;
    chequeNumber?: string;
  }[];
  feePreview: FeePreview;
  feeAmount: string;
  tags: Array<{
    id: string;
    name: string;
    tagColor: string;
  }>;
  tagIds?: string[];
  clientDocuments: {
    id: string;
    details: {
      [key: string]: ClientDocumentDetailDto;
    };
  }[];
  files?: ClientDocumentDisplayFile[];
  walletId?: string;
  walletLabel?: string;
  walletName?: string;
  paymentDetails?: PaymentDetail[];
}

export type BillPaymentCompleteParams = {
  id: string;
  merchant: string;
  billAccountNumber: string;
  amount: string;
  currency: string;
  remarks: string;
  remarkChannel: string;
  attachments: string[];
  walletId: string;
  processedAt?: string;
  status?: string;
  overrideProps?: InstructionOverrideProps;
  feeAmount?: number;
  tagIds?: string[];
  files?: ClientDocumentDisplayFile[];
  paymentDetails?: PaymentDetail[];
  paymentAdditionalDetails?: string;
};

export interface BillPaymentsListDetail extends BillPaymentsListItem {}

export const BillPaymentStatus = {
  SCHEDULED: 'SCHEDULED',
  REQUESTED: 'REQUESTED',
  PROCESSING: 'PROCESSING',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  PENDING_FUNDING: 'PENDING_FUNDING'
};

export async function queryBillPayments(params: any): Promise<RequestData<BillPaymentsListItem>> {
  if (params.date) {
    params.from = params.date[0];
    params.to = params.date[1];
    delete params.date;
  }
  if (params['user,email']) {
    params.email = params['user,email'];
    delete params['user,email'];
  }

  if (!params['status']) {
    params.status = 'OVERVIEW';
  }

  const data = await request<RequestData<BillPaymentsListItem>>('/bill-payments', {
    params
  });
  return data;
}

export async function queryBillPaymentsDetail(params: { id: string }) {
  const data = await request<BillPaymentsListItem>(`/bill-payments/${params.id}`);

  return {
    data
  };
}

export async function createBillPayment(params: {
  userId: string;
  merchant: string;
  billAccountNumber: string;
  amount: string;
  currency: string;
  remarks: string;
  remarkChannel: string;
  attachments: string[];
  walletId: string;
  status?: string;
  scheduledOn?: string;
  overridedProps?: InstructionOverrideProps;
  feeAmount?: number;
  tagIds?: string[];
  files: ClientDocumentDisplayFile[];
  paymentDetails?: PaymentDetail[];
  paymentAdditionalDetails?: string;
}) {
  return request<BillPaymentsListDetail>(`/bill-payments`, {
    method: 'POST',
    data: params
  });
}

export async function processBillPayment(params: {
  id: string;
  tagIds?: string[];
  merchant: string;
  billAccountNumber: string;
  amount: string;
  currency: string;
  walletId: string;
  processedAt?: string;
  status?: string;
  paymentDetails?: PaymentDetail[];
  files: ClientDocumentDisplayFile[];
  // admin
  remarks: string;
  remarkChannel: string;
  attachments: string[];
}) {
  return request<BillPaymentsListDetail>(`/bill-payments/${params.id}/processing`, {
    method: 'POST',
    data: params
  });
}

export async function completeBillPayment(params: BillPaymentCompleteParams) {
  return request<BillPaymentsListDetail>(`/bill-payments/${params.id}/complete`, {
    method: 'POST',
    data: params
  });
}

export async function cancelBillPayment(params: { id: string; remark?: string; remarkChannel?: string }) {
  return request<BillPaymentsListDetail>(`/bill-payments/${params.id}/cancel`, {
    method: 'POST',
    data: params
  });
}

export async function editBillPaymentDetail(params: {
  id: string;
  description: string;
  userId: string;
  operatorRemark?: string;
  remarkChannel?: string;
  attachments: string[];
  billAccountNumber: string;
  merchant: string;
  amount: string;
  currency: string;
  walletId?: string;
  tagIds?: string[];
  files: ClientDocumentDisplayFile[];
  paymentDetails?: PaymentDetail[];
  status?: string;
  scheduledOn?: Date;
  paymentAdditionalDetails?: string;
  metadata?: object;
}) {
  const postData = await request(`/bill-payments/${params.id}/edit`, {
    method: 'POST',
    data: {
      ...params,
      method: 'post'
    }
  });
  return {
    postData
  };
}
